import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Ajay from "../../assets/testimonial/ajayrawal.png"
import Aditya from "../../assets/testimonial/Aditya.png"
import Saymon from "../../assets/testimonial/saymon.png"
import Alex from "../../assets/testimonial/Alex Thomas.png"
import Arshad from "../../assets/testimonial/Arshad Qureshi.png"


import "./style.css";

// import required modules
import { Navigation } from "swiper/modules";
import Card from "./Card";

const data = [
  {
    content:
    "Chaitanyaai exceeded our expectations with their AI consulting and end-to-end AI development services. Their commitment to quality assurance and compliance was exceptional. The team’s expertise and professionalism were evident in every step of the process. Highly recommend Chaitanyaai for any AI-related needs!",
    name: "Ajay Rawal",
    profile: Ajay,
    position: "Founder, Room Reveal",
  },
  {
    content:
    "Chaitanyaai's AI consulting and end-to-end development services have transformed Urbanlyfe's operations. Their expertise in AI has enabled us to streamline processes and enhance customer experience on our organic products e-commerce platform. Highly recommend their professional and innovative approach!",
    name: "Arshad Qureshi",
    profile: Arshad,
    position: "Founder, Urbanlyfe",
  },
  {
    content:
    "Chaitanyaai has been an exceptional partner for HumanifyAi. Their expertise in AI Consulting and End-to-End Development has been invaluable. Collaborating with them has significantly enhanced our automation capabilities. Their professionalism and dedication make them a standout choice for any AI-driven project.",
    name: "Aditya",
    profile: Aditya,
    position: "Co-Founder, HumanifyAi",
  },
  {
    content:
    "Chaitanyaai LLP delivered an exceptional end-to-end AI solution for Immersio. Their expertise and dedication significantly enhanced our AI content generation. The seamless integration and innovative approach were outstanding. Highly recommend Chaitanyaai for their professionalism and top-notch service.",
    name: "Saymon",
    profile: Saymon,
    position: "Product Development Lead, Immersio",
  },
  {
    content:
    "Chaitanyaai LLP delivered an exceptional end-to-end AI solution for our content generation product. Their expertise in Generative AI development was instrumental in enhancing our capabilities. The team's professionalism and dedication were evident throughout the project. We couldn't have asked for a better partner. Highly recommended!",
    name: "Alex Thomas",
    profile: Alex,
    position: "Product Lead",
  },
];

export default function Testimonial() {
  // const width = useState(window.screen.width);

  return (
    <div className="testi">
      <p className="text-center text-white text-[30px]">
        <span className="comp_name text-3xl">Testimonial </span>
      </p>


      <Swiper
        loop={true}
        spaceBetween={30}
        slidesPerView={3}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper hidden md:hidden lg:block md:mt-10 mt-10"
      >
        {data.map((item) => (
          <SwiperSlide>
            <Card data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        loop={true}
        spaceBetween={30}
        slidesPerView={1}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper block md:block lg:hidden"
      >
        {data.map((item) => (
          <SwiperSlide>
            <Card data={item} />
          </SwiperSlide>
        ))}
      </Swiper>




      {/* {width > 1000 ? ( */}
      {/*   <Swiper */}
      {/*     loop={true} */}
      {/*     spaceBetween={30} */}
      {/*     slidesPerView={3} */}
      {/*     navigation={true} */}
      {/*     modules={[Navigation]} */}
      {/*     className="mySwiper" */}
      {/*   > */}
      {/*     {data.map((item) => ( */}
      {/*       <SwiperSlide> */}
      {/*         <Card data={item} /> */}
      {/*       </SwiperSlide> */}
      {/*     ))} */}
      {/*   </Swiper> */}
      {/* ) : ( */}
      {/*     <Swiper */}
      {/*       loop={true} */}
      {/*       spaceBetween={30} */}
      {/*       slidesPerView={1} */}
      {/*       navigation={true} */}
      {/*       modules={[Navigation]} */}
      {/*       className="mySwiper" */}
      {/*     > */}
      {/*       {data.map((item) => ( */}
      {/*         <SwiperSlide> */}
      {/*           <Card data={item} /> */}
      {/*         </SwiperSlide> */}
      {/*       ))} */}
      {/*     </Swiper> */}
      {/*   )} */}

    </div>
  );
}
