import React from "react";
import c1 from '../../assets/c1.png'
import c2 from '../../assets/c2.png'
import c3 from '../../assets/c3.png'
import './style.css'

const Certifications = () => {
  return (
    <div>
      <p className="text-center text-white text-[30px]">
        <span className="comp_name-r">Awards </span>
      </p>
      <p className="text-center text-white text-[20px] mt-8">
        Our recent awards and certifications reflect dedication, trust, and quality, ensuring we exceed the highest industry standards
      </p>
      <div className="grid grid-cols-3 gap-1 items-center mt-12 lg:justify-evenly 
        md:justify-evenly img_cont mb-32 justify-between place-items-center">
        <img src={c1} className='' alt="" />
        <img src={c2} className='' alt="" />
        <img src={c3} className='' alt="" />
      </div>
    </div>
  );
};

export default Certifications;
