import React from "react";
import Layer_1 from "../public/Layer_1.png";
import Layer_2 from "../public/Layer_1 (1).png";
import Layer_3 from "../public/Layer_1 (2).png";

const Involvement = () => {
  return (
    <div className="flex flex-col items-center mt-28 lg:mt-[10%]">
      <div className="text-center text-wrap mb-8">
        <h1 className=" font-black text-[45.75px]">
          ChaitanyaAi’s Involvement
        </h1>
        <p className="w-[80%] m-auto text-[16.73px] font-medium">
          An In-depth Examination of ChaitanyaAi's Substantial Contribution and
          Active Involvement in the Project
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-6 mt">
        <div className="card lg:p-5 shadow-xl flex flex-col h-[412px] lg:w-[381px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Layer_1}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[337px]">AI Consulting</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[326.58px]">
            ChaitanyaAi offers expert AI consulting services for Room Reveal,
            assisting in optimizing user experience and enhancing platform
            functionality. Through comprehensive analysis and strategic
            insights, ChaitanyaAi guides Room Reveal in leveraging AI to
            streamline processes, improve recommendation algorithms, and enhance
            user engagement.
          </p>
        </div>
        <div className="card lg:p-5 shadow-xl flex flex-col h-[412px] lg:w-[381px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Layer_2}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[337px]">End-to-End AI Development</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[326.58px]">
            ChaitanyaAi spearheads the end-to-end AI development process for Room Reveal, from conceptualization to implementation. Utilizing cutting-edge machine learning techniques and advanced algorithms, ChaitanyaAi engineers tailor-made solutions to enrich Room Reveal's capabilities. This includes developing AI-powered features for image recognition, personalized recommendations of images, and seamless integration of user-wanted images on a wall, ensuring a dynamic and immersive experience for Room Reveal users.
          </p>
        </div>
        <div className="card lg:p-5 shadow-xl flex flex-col h-[412px] lg:w-[381px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Layer_3}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[337px]">Quality Assurance and Compliance</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[326.58px]">
            ChaitanyaAi is responsible for overseeing quality assurance and compliance efforts within Room Reveal's AI development processes, ensuring that AI models and algorithms meet industry standards and regulatory requirements. By implementing rigorous testing protocols and adhering to best practices in data privacy and security, ChaitanyaAi safeguards Room Reveal against potential risks and liabilities, building trust and confidence among users and stakeholders
          </p>
        </div>
      </div>
    </div>
  );
};

export default Involvement;
