import React from 'react'
import mark from '../../assets/mark.png'
// import profile from '../../assets/profile.png'

const Card = ({data}) => {
  return (
    <>
        <img src={mark} alt="" />
          <p>
            {data.content}
          </p>
          <div className='flex items-center justify-center flex-row h-fit'>
            <img src={data.profile} alt="" />
            <div className='ml-2'>
              <p>{data.name}</p>
              <p>{data.position}</p>
            </div>
          </div>
    </>
  )
}

export default Card
