// import logo from './logo.svg';
import './App.css';
// import Header from './component/Header';
// import Bg from './component/BG/Bg';
import Home from './pages/Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Portfoilio from './pages/Portfoilio';
import Contact from './pages/Contact';
import Company from './pages/Company';
import Career from './pages/Career';
import Urbanlyfe from './pages/Urbanlyfe';
// import Roomreveal from './roomreveal/src/app/page'
import Roomreveal from './pages/Roomreveal'
import ComingSoon from './pages/ComingSoon'
import AICommerce from './pages/AIinE-Commerce';
import RagvsFineTune from './pages/RagvsFineTune';
import CaseStudies from './pages/CaseStudies';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/portfolio",
    element: <Portfoilio />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/roomreveal",
    element: <Roomreveal />,
  },
  {
    path: "/urbanlyfe",
    element: <Urbanlyfe />,
  },
  {
    path: "/career",
    element: <Career />,
  },
  {
    path: "/comingsoon",
    element: <ComingSoon />,
  },
  {
    path: "/aICommerce",
    element: <AICommerce />,
  },
  {
    path: "/caseStudies",
    element: <CaseStudies />,
  },
  {
    path: "/ragfinetune",
    element: <RagvsFineTune />,
  },
]);

export default function App() {
  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  )
}
