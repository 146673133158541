import Header from "../component/Header";
import Problemident from "../components/Problemiden";
// import Imagee from "../components/Imagee";
import Involvement from "../components/involvement";
import HighlightedF from "../components/HighlightedF";
import Conclusion from "../components/Conclusion";
import ClientReview from "../components/ClientReview";
// import Morecasestudy from "../components/Morecasestudy";
import Footer from "../component/footer/Footer";
import './index.css';
import '../App.css';
import Group1 from '../assets/public/Group 4.png';
import Group2 from '../assets/public/Group 2.png';
// import logo from '../assets/roomrevealassets/llo.png';
// import macb from '../assets/roomrevealassets/MacBook Front View Mockup 1.png'
// import eggg from '../assets/roomrevealassets/Ellipse 1.png'
// import ldos from '../assets/roomrevealassets/Limited Design Options.png'
// import divs from '../assets/roomrevealassets/Difficulty in Visualizing.png'
// import locs from '../assets/roomrevealassets/Lack of Customization.png'
// import uicx from '../assets/roomrevealassets/User Interface Complexity.png'
// import full from '../assets/roomrevealassets/fullmac.png'
// import grid from '../assets/roomrevealassets/Images Grid.png'


function Roomreveal() {
  return (
    <div>
      <div className="bg-[#050505] h-[885px] w-full lg:p-0 text-white">
        <Header />
        <div
          className="Hero flex flex-col justify-center items-center"
          id="Hero"
        >
          <div className="lg:w-[1200px] w-full h-[156px] text-center mt-[10%]">
            <h1 className="flex text-2xl lg:text-[50.07px] font-black flex-col">
              <span>
                <span className="bg-gradient-to-r from-[#FFC107]/100 leading-normal
                  via-[#FFC107]/100 to-[#FFE607] inline-block text-transparent 
                  bg-clip-text">
                  Room Reveal
                </span>
                - See It Before You Install
              </span>
              <span>A Case Study</span>
            </h1>
          </div>

          <p className="lg:w-[830.43px] text-center font-medium lg:text-[16.73px]">
            Introducing Room Reveal, a dynamic web platform that revolutionizes
            wallpaper visualization. Seamlessly blending user-generated content
            with pre-existing designs, Room Reveal offers endless possibilities
            for transforming living spaces.
          </p>

          <a className=" bg-[#F28C46]/100 font-bold text-[15px] 
            text-center mt-[5%] rounded-full text-black lg:w-[192px] h-[52px]" 
            href={`/contact`}>
            <button className="font-bold text-[15px] text-center 
              text-black w-[192px] h-[52px]">
              Get a free Quote
            </button>
          </a>

          <div>
            <img
              className="mt-[21.62px]"
              src={Group1}
              height={783.38}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="lg:flex-row flex-col ProjectInfo w-fit h-fit flex items-center 
        justify-center m-auto mt-32 lg:mt-[700px] pl-4 pt-4 shadow-xl rounded-[16px]">
        <div className="w-[277.5px] h-[135px]">
          <span className=" font-medium text-[#454B53] text-[16.59px]">
            Industry
          </span>
          <h2 className=" font-bold text-[#282B2F] text-[21.75px]">
            Interior design
          </h2>
        </div>
        <div className="w-[277.5px] h-[135px]">
          <span className=" font-medium text-[#454B53] text-[16.59px]">
            Platform
          </span>
          <h2 className=" font-bold text-[#282B2F] text-[21.75px]">
            Web, Cloud
          </h2>
        </div>
        <div className="w-[277.5px] h-[135px]">
          <span className=" font-medium text-[#454B53] text-[16.59px]">
            project lenght
          </span>
          <h2 className=" font-bold text-[#282B2F] text-[21.75px]">
            4 mounths
          </h2>
        </div>
        <div className="w-[277.5px] h-[135px]">
          <span className=" font-medium text-[#454B53] text-[16.59px]">
            Services
          </span>
          <h2 className=" font-bold text-[#282B2F] text-[21.75px]">
            Ai Consulting, Ai Development
          </h2>
        </div>
      </div>

      <div className="lg:flex-col flex-col About px-4 lg:w-[1200px] 
        lg:h-[622.86px] flex items-center m-auto mt-[10%]">
        <div className="">
          <h2 className="text-current font-black lg:text-[46.13px] text-[#282B2F]">
            About the Project
          </h2>
          <p>
            Room Reveal is an innovative web platform designed for visualizing
            wallpapers. Users can effortlessly upload their custom designs or
            choose from a variety of built-in options to visualize their walls
            in real time. By seamlessly integrating user-generated content with
            pre-existing designs, Room Reveal empowers users to explore
            countless possibilities for transforming their living spaces.
            Whether experimenting with personal creations or browsing curated
            selections, the platform offers an immersive experience
          </p>
        </div>
        <div>
          <img
            className="mt-[25px]"
            src={Group2}
            alt=""
          />
        </div>
      </div>

      <Problemident />
      {/* <Imagee /> */}
      <Involvement />
      <HighlightedF />
      <Conclusion />
      <ClientReview />
      {/* <Morecasestudy /> */}
      <Footer />
    </div>
  );
}
export default Roomreveal;

