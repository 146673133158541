import React from 'react'
import photo from '../../assets/teampics/Shaishav.png';
import photo1 from '../../assets/teampics/Lakshyadip.png';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const teamMembers = [
  {
    name: 'Shaishav Surati',
    designation: 'Founder, CEO',
    profilePic: photo,
    description: 'Experienced professional with 3 years of expertise and an additional 2 years in diverse technologies, contributing innovative solutions and technical proficiency to drive team success and deliver outstanding results.'
  },
  {
    name: 'Lakshyadip Patel',
    designation: 'General Manager',
    profilePic: photo1,
    description: 'Experienced in management with 3 years of proven leadership, driving team success, and optimizing processes. Dedicated to delivering excellence and fostering innovation in every project.'
  },
]

const OurTeam = () => {
  const swiperRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);


  const handleNext = () => {
    //onclick scroll to next slide
    // setActiveIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
    swiperRef.current.swiper.slideNext();
  }


  const handlePrevious = () => {
    //onclick scroll to previous slide
    // setActiveIndex((prevIndex) => (prevIndex - 1 + teamMembers.length) % teamMembers.length);
    swiperRef.current.swiper.slidePrev();
  }

  const currentMember = teamMembers[activeIndex];

  return (
    <section className='w-full flex flex-col gap-10 lg:gap-0 lg:flex-row my-16 
      lg:h-[364px] items-center font-poppins'>
      <div className='text-center lg:text-left'>
        <h3 className='text-white text-4xl'>
          Meet
        </h3>
        <h1 className='comp_name font-bold text-[46px] font-poppins leading-[55px]'>
          Our Team
        </h1>
      </div>
      <div className='lg:ml-32 flex justify-center items-center'>
        <img className='lg:size-96 size-52 md:size-96 rounded-lg 
          border-emerald-600 border-2 sm:size-52'
          src={currentMember.profilePic} alt={'userphoto'} />
      </div>
      <Swiper
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        ref={swiperRef} 
        className='flex overflow-x-hidden lg:max-w-[576px] lg:min-h-[520px]'>
        {
          teamMembers.map((member, index) => (
            <div className='flex-grow ml-5 h-full bg-gradient-to-r 
              from-[#6aa1b96f] bg-opacity-15 to-[#ddeff474] relative p-[1px] 
              rounded-[10px] min-w-[556px] lg:min-h-[364px]' key={index}>
              <SwiperSlide className='bg-black text-white rounded-[10px] 
                p-10 flex flex-col justify-between h-full lg:h-[364px]'>
                <div className='flex flex-col gap-6 '>
                  <div className='flex flex-col'>
                    <div className='font-bold text-[26px] leading-[40px]'>{member.name}</div>
                    <div className='text-base font-normal'>{member.designation}</div>
                  </div>
                  {member.description}
                </div>
                <div className='flex justify-end w-full'>
                  <div>
                    <button className='px-4 py-2 rounded-[5px]'>
                      <img src={arrowLeft} alt={'previous'} onClick={handlePrevious} />
                    </button>
                    <button className='px-4 py-2 rounded-[5px]'>    
                      <img src={arrowRight} alt={'next'}  onClick={handleNext}/>
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))
        }
      </Swiper>
    </section>
  )
}

export default OurTeam
