import React from 'react'
import Roomreveal from '../../assets/partnerwith/Logo main.png'
import Urbanlyfe from '../../assets/partnerwith/logourbanlyfe.png'
import Immersio from '../../assets/partnerwith/immersio.png'
// import Humanify from '../../assets/partnerwith/humanify.png'
// import Einfochips from '../../assets/partnerwith/einfochips.png'

import './style.css'

const PortfolioCards = () => {
  return (
    <div className='w-full flex items-center justify-start flex-col mt-12 p-2 pb-28'>


      <div className='bubble_1'></div>
      <div className='w-full md:w-[70%] flex items-center justify-around flex-col md:flex-row border border-slate-400 rounded-xl p-4 md:p-8 my-4 p_card'>
        <div className='w-[70%] md:w-1/3'>
          <img src={Roomreveal} className='rounded-xl' alt="" />
        </div>
        <div className='w-full ml-5 flex items-start justify-center flex-col text-white p-2'>
          <div className='text-lg mb-4'>
            Room Reveal : Wall Paper Made Easy
          </div>
          <div className='text-sm my-4'>
            Room Reveal, a dynamic platform that revolutionizes wallpaper visualization.
          </div>
          <a href={`/Roomreveal`}>
            <button className='bg-[#35B5D4] px-4 py-2 rounded-full my-8'>Learn more</button>
          </a>
        </div>
      </div>


      <div className='bubble_2'></div>
      <div className='w-full md:w-[70%] flex items-center justify-around flex-col md:flex-row-reverse border border-slate-400 rounded-xl p-4 md:p-8 my-4 p_card'>
        <div className='w-[70%] md:w-1/3'>
          <img src={Urbanlyfe} className='rounded-xl' alt="" />
        </div>
        <div className='w-full flex items-start justify-center flex-col text-white p-2'>
          <div className='text-lg mb-4'>
            Urbanlyfe : Towards Sustainable
          </div>
          <div className='text-sm my-4'>
            Urbanlyfe is a E-Commerce Platform for Organic Products.
          </div>
          <a href={`/Urbanlyfe`}>
            <button className='bg-[#35B5D4] px-4 py-2 rounded-full my-8'>
              Learn more
            </button>
          </a>
        </div>
      </div>


      <div className='bubble_1'></div>
      <div className='w-full md:w-[70%] flex items-center justify-around flex-col md:flex-row border border-slate-400 rounded-xl p-4 md:p-8 my-4 p_card'>
        <div className='w-[70%] md:w-1/3'>
          <img src={Immersio} className='rounded-xl' alt="" />
        </div>
        <div className='w-full ml-5 flex items-start justify-center flex-col text-white p-2'>
          <div className='text-lg mb-4'>
            Immersio : Transform Text into Engaging Visuals for Effective Digital Marketing
          </div>
          <div className='text-sm my-4'>
            Immersio, Create Unique Visual Content from Text Descriptions Easily with This Platform.
          </div>
          <a href={`/ComingSoon`}>
            <button className='bg-[#35B5D4] px-4 py-2 rounded-full my-8'>Learn more</button>
          </a>
        </div>
      </div>



      {/* <div className='bubble_2'></div> */}
      {/* <div className='w-full md:w-[70%] flex items-center justify-around flex-col md:flex-row-reverse border border-slate-400 rounded-xl p-4 md:p-8 my-4 p_card'> */}
      {/*   <div className='w-[70%] md:w-1/3'> */}
      {/*     <img src={Humanify} className='rounded-xl' alt="" /> */}
      {/*   </div> */}
      {/*   <div className='w-full flex items-start justify-center flex-col text-white p-2'> */}
      {/*     <div className='text-lg mb-4'> */}
      {/*       Humanify : Towards Sustainable */}
      {/*     </div> */}
      {/*     <div className='text-sm my-4'> */}
      {/*       Humanify is a E-Commerce Platform for Organic Products. */}
      {/*     </div> */}
      {/*     <a href={`/Urbanlyfe`}> */}
      {/*       <button className='bg-[#35B5D4] px-4 py-2 rounded-full my-8'> */}
      {/*         Learn more */}
      {/*       </button> */}
      {/*     </a> */}
      {/*   </div> */}
      {/* </div> */}



      {/* <div className='bubble_1'></div> */}
      {/* <div className='w-full md:w-[70%] flex items-center justify-around flex-col md:flex-row border border-slate-400 rounded-xl p-4 md:p-8 my-4 p_card'> */}
      {/*   <div className='w-[70%] md:w-1/3'> */}
      {/*     <img src={Einfochips} className='rounded-xl' alt="" /> */}
      {/*   </div> */}
      {/*   <div className='w-full ml-5 flex items-start justify-center flex-col text-white p-2'> */}
      {/*     <div className='text-lg mb-4'> */}
      {/*       Einfochips : Transform Text into Engaging Visuals for Effective Digital Marketing */}
      {/*     </div> */}
      {/*     <div className='text-sm my-4'> */}
      {/*       Einfochips, Create Unique Visual Content from Text Descriptions Easily with This Platform. */}
      {/*     </div> */}
      {/*     <a href={`/ComingSoon`}> */}
      {/*       <button className='bg-[#35B5D4] px-4 py-2 rounded-full my-8'>Learn more</button> */}
      {/*     </a> */}
      {/*   </div> */}
      {/* </div> */}

    </div>
  )
}

export default PortfolioCards
