import './style.css'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaInstagram, FaXTwitter, FaYoutube, FaLinkedinIn } from "react-icons/fa6";



const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs .sendForm('service_irekndn', 
      'template_vdkoroz', 
      form.current, { publicKey: 'SZnjQEdlIN3c4GNEB', })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div>

      <div className="px-4 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 
        justify-center py-20 lg:px-8">
        <div className='col-span-1'>
        </div>

        <div className="hidden md:hidden lg:flex col-span-2 w-full h-full p-8 
          rounded-2xl text-white">
          <div className='flex items-start md:items-center justify-center 
            flex-col h-full w-full md:w-1/3'>
          </div>
          <div className='flex items-start justify-center flex-col h-full'>
            <p className='comp_name mb-4 md:mb-8 mt-8 md:mt-0 
              text-2xl'>Contact Us</p>
            <div>
              <div className='text-xs flex items-center'>
                <span className='text-2xl m-2'>
                  <MdOutlineEmail /></span> info@chaitanyaai.in</div>
              <div className='text-xs flex items-center'>
                <span className='text-2xl mx-2'>
                  <IoCallOutline /></span> +91 99135 68622</div>
              <div className='text-xl flex m-2 mt-8'>

                <a href='https://www.instagram.com/joinchaitanyaai' 
                  className='border rounded-full p-2'>
                  <span className=''> 
                    <FaInstagram /></span>
                </a>
                <a href='https://x.com/ChaitanyaaiLlp' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaXTwitter /></span>
                </a>
                <a href='https://youtube.com/@chaitanya-ai' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaYoutube /></span>
                </a>
                <a href='https://www.linkedin.com/company/chaitanyaai/' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaLinkedinIn /></span>
                </a>

              </div>
            </div>    
          </div>
        </div>

        <div className=" flex col-span-2 w-full h-full border 
          border-gray-500 lg:p-12 rounded-2xl">

          <form 
            className="max-w-md mx-auto mt-5 bg-transparent rounded-lg 
            shadow-lg lg:p-6 space-y-6 w-full backdrop-blur-sm"
            ref={form} 
            onSubmit={sendEmail}
          >
            <h2 className="text-2xl font-bold text-white mb-6">Contact Us</h2>

            <div>
              <label htmlFor="name" className="block text-white text-sm 
                font-bold mb-2">Name</label>
              <input
                className="w-full px-3 py-2 bg-transparent border-b 
                border-white focus:outline-none focus:border-blue-500 
                focus:bg-white focus:text-black text-white placeholder-gray-300"
                id="name"
                name="from_name"
                type="text"
                autoComplete="name"
                placeholder="Your name"
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>

            <div>
              <label htmlFor="Email" className="block text-white text-sm 
                font-bold mb-2">Email</label>
              <input
                className="w-full px-3 py-2 bg-transparent border-b 
                border-white focus:outline-none focus:border-blue-500 
                focus:bg-white focus:text-black text-white placeholder-gray-300"
                id="Email"
                name="Email"
                type="email"
                autoComplete="email"
                placeholder="your@email.com"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </div>

            <div>
              <label htmlFor="Contact" className="block text-white text-sm 
                font-bold mb-2">Phone Number</label>
              <input
                className="w-full px-3 py-2 bg-transparent border-b 
                border-white focus:outline-none focus:border-blue-500 
                focus:bg-white focus:text-black text-white placeholder-gray-300"
                id="Contact"
                name="Contact"
                type="tel"
                autoComplete="tel"
                placeholder="(123) 456-7890"
                onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                required
              />
            </div>

            <div>
              <label htmlFor="Message" 
                className="block text-white text-sm font-bold mb-2">Message</label>
              <textarea
                className="w-full px-3 py-2 bg-transparent border-b 
                border-white focus:outline-none focus:border-blue-500 
                focus:bg-white focus:text-black text-white placeholder-gray-300"
                id="Message"
                name="Message"
                rows="4"
                placeholder="Your message here"
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                required
              ></textarea>
            </div>

            <div className="w-full">
              <button
                type="submit" 
                value="Send" 
                className="w-full bg-gradient-to-r from-[#6D7DFF] 
                to-[#00FFB2] text-white font-bold py-2 px-4 hover:opacity-90 
                focus:outline-none focus:ring-2 focus:ring-blue-500 
                focus:ring-opacity-50 transition-colors duration-300"
              >
                Submit
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  )

  // return (
  //   <form ref={form} onSubmit={sendEmail}>
  //     <label>Name</label>
  //     <input type="text" name="user_name" />
  //     <label>Email</label>
  //     <input type="email" name="user_email" />
  //     <label>Message</label>
  //     <textarea name="message" />
  //     <input type="submit" value="Send" />
  //   </form>
  // );
};

export default ContactForm
