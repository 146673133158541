import React from 'react'

const Conclusion = () => {
  return (
    <div className='mt-28 px-4 lg:mt-[10%] text-center flex flex-col items-center'>
        <h1 className=' font-black text-[45.56px]'>The Conclusion</h1>
        <p className=' text-[#77808D] font-medium lg:w-[830px]'>ChaitanyaAi is committed to elevating Room Reveal's platform through AI consulting and end-to-end development services. By harnessing the power of artificial intelligence, ChaitanyaAi aims to enhance user satisfaction, optimize functionality, and drive innovation within Room Reveal. With a focus on personalized experiences and cutting-edge technology, ChaitanyaAi strives to empower Room Reveal to remain at the forefront of the interior design industry, offering users unparalleled tools for visualizing and transforming their living spaces.</p>
    </div>
  )
}

export default Conclusion
