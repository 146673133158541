import React from 'react'
import Dropdown from './Dropdown'
import './style.css'

const data = [
    {
        title: "Transformational Expertise",
        content: "We weave expertise into bespoke AI solutions that propel your business forward. Our team combines innovation and industry knowledge to deliver transformative results."
    },
    {
        title: "Democratizing AI",
        content: "Our mission is to make AI accessible for every business, regardless of size or industry. We believe that the benefits of AI should be within reach, understandable, and tailored to individual needs."
    },
    {
        title: "Beyond Technology Implementation",
        content:"We go beyond simply implementing technology; we empower your team to leverage AI effectively. Our approach fosters a culture of data-driven decision-making and continuous innovation."
    }
]

const GroupDropdowns = () => {

  return (
    <div className='grou_drop'>
    <p className="text-center text-white mt-48 mb-12 text-[20px] md:text-[30px]">
                Why <span className="comp_name">ChaitanyaAi </span>
                As Your AI Partner
            </p>
    
      {data && data.map(item => (
        <Dropdown title={item.title} content={item.content} />
      ))}
    </div>
  )
}

export default GroupDropdowns
