import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Dropdown } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import logo from '../assets/logo.png'

const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-14 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">           

          <a href={`/`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white">
            Home
          </a>
          <a href={`/portfolio`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white">
            Portfolio
          </a>
          <div className="relative group flex flex-row text-white font-semibold">
            Company
            <ChevronDownIcon className="ml-2 font-semibold group-hover:opacity-50 h-5 w-5 transition duration-150 ease-in-out"/>
            <div className="hidden group-hover:block absolute
              rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 
              transition duration-150 ease-in-out pr-6">
              <div className="relative flex flex-col">
                <a
                  href={`/Company`}
                  className="flex rounded-lg mt-12 pr-0
                  p-4 transition duration-150 ease-in-out focus:outline-none
                  hover:opacity-50 focus-visible:ring focus-visible:ring-opacity-50"
                >
                  <div className="">
                    <p className="text-sm font-semibold text-white">About</p>
                  </div>
                </a>
                <a
                  href={`/Career`}
                  className="flex rounded-lg pr-0
                  p-4 transition duration-150 ease-in-out focus:outline-none
                  hover:opacity-50 focus-visible:ring focus-visible:ring-opacity-50"
                >
                  <div className="flex flex-row">
                    <h1 className="text-sm font-semibold text-white">Careers </h1>
                    {/* <h1 className='text-sm text-nowrap rounded-sm font-semibold text-gray-900 */}
                    {/*   bg-gradient-to-r from-teal-400 to-cyan-200 ml-2'> Coming soon </h1> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <a href={`/caseStudies`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white">
            Case Studies
          </a>
          <a href={`/contact`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white">
            Contact Us
          </a>
          {/* <a href={`/aICommerce`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white"> */}
          {/*   AI in E-Commerce */}
          {/* </a> */}
          {/* <a href={`/ragfinetune`} className="transition duration-150 ease-in-out hover:opacity-50 text-sm font-semibold leading-6 text-white"> */}
          {/*   RAG Vs Fine-tuning */}
          {/* </a> */}
        </Popover.Group>

      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href={`/`} className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-14 w-auto"
                src={logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">

                <a
                  href={`/`}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "
                >
                  Home
                </a>
                <a
                  href={`/portfolio`}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "
                >
                  Portfolio
                </a>
                <div className="relative group flex flex-col text-white font-semibold">
                  <p className='group-hover:opacity-50'>

                    Company
                  </p>
                  {/* <ChevronDownIcon className="ml-2 font-semibold group-hover:opacity-50 h-5 w-5 transition duration-150 ease-in-out"/> */}
                  <div className="hidden group-hover:block absolute
                    rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 
                    transition duration-150 ease-in-out pr-6 ml-20">
                    <div className="relative flex flex-row w-fit">
                      <a
                        href={`/Company`}
                        className="flex rounded-lg
                        p-4 pt-1 transition duration-150 ease-in-out focus:outline-none
                        hover:opacity-50 focus-visible:ring focus-visible:ring-opacity-50"
                      >
                        <div className="">
                          <p className="text-sm font-semibold text-white">About</p>
                        </div>
                      </a>
                      <a
                        href={`/Career`}
                        className="flex rounded-lg
                        p-4 pt-1 transition duration-150 ease-in-out focus:outline-none
                        hover:opacity-50 focus-visible:ring focus-visible:ring-opacity-50"
                      >
                        <div className="flex flex-row">
                          <h1 className="text-sm font-semibold text-white">Careers </h1>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <a
                  href={`/caseStudies`}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "
                >
                  Case Studies
                </a>
                <a
                  href={`/contact`}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "
                >
                  Contact Us
                </a>
                {/* <a */} 
                {/*   href={`/aICommerce`} */} 
                {/*   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "> */}
                {/*   AI in E-Commerce */}
                {/* </a> */}
                {/* <a */} 
                {/*   href={`/ragfinetune`} */} 
                {/*   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white "> */}
                {/*   RAG Vs Fine-tuning */}
                {/* </a> */}
              </div>

            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
