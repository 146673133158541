import React from "react";
import profile from "../public/profile.png";
import Ajay from '../assets/testimonial/ajayrawal.png'

const ClientReview = () => {
  return (
    <div className="flex justify-center">
      <div className="lg:mt-[10%] lg:w-[664px] h-fit lg:gap-10 border mx-2 mt-8
        border-black/70 rounded-[24px] flex flex-col justify-center items-center">
        <p className="text-center text-[21.14px] font-normal lg:w-[605px] p-2">
          The app was delivered{" "}
          <span className="bg-[#F28C46]/70">ahead of schedule,</span> leaving
          the client{" "}
          <span className="bg-[#F28C46]/70">
            impressed with ChaitanyaAi efficiency. The vendor ensured a seamless
            customer experience
          </span>{" "}
          by always addressing queries and requests promptly. Moreover,they{" "}
          <span className="bg-[#F28C46]/70">worked flexibly</span> to
          accommodate any ad hoc tasks and
          <span className="bg-[#F28C46]/70">
            followed a professional approach.
          </span>
        </p>

        <div className="flex justify-center items-center gap-5">
          <div className=" rounded-full h-[56px] lg:w-[56px]">
            <img src={Ajay} className='rounded-full' width={56} height={56} alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <h6 className=" font-bold text-[16.73px]">Ajay Rawal</h6>
            <p className="text-[#77808D] text-[13.34px] ">Founder, ChaitanyaAi</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientReview;
