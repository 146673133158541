import React from "react";
import Header from "../component/Header";
import './style.css'
import Bg from "../component/BG/Bg";
// import Footer from "../component/footer/Footer";

const ComingSoon = () => {
  return (
    <div className="w-full min-h-full">

      <Bg />

      <Header />

      <div className="lg:mx-[150px]">

        <p className="text-center text-white mt-24 md:mt-36 text-2xl lg:text-[60px] md:text-[60px]">
          <span className="comp_name">Update Soon</span>
        </p>

        {/* why chaitanya AI */}

      </div>

      {/* <Footer /> */}

    </div>
  );
};

export default ComingSoon;

