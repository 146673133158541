import React from "react";
import p1  from '../../assets/platform work/p1.png'
import p2  from '../../assets/platform work/p2.png'
import p3  from '../../assets/platform work/p3.png'
import p4  from '../../assets/platform work/p4.png'
import p5  from '../../assets/platform work/p5.png'
import p6  from '../../assets/platform work/p6.png'
import p7  from '../../assets/platform work/p7.png'
import p8  from '../../assets/platform work/4.png'
import p9  from '../../assets/platform work/9.png'
import p10 from '../../assets/platform work/Open-AI.png'
import p11 from '../../assets/platform work/2-1.png'
import p12 from '../../assets/platform work/6-3.png'
import p13 from '../../assets/platform work/8-2.png'
import p14 from '../../assets/platform work/p10.png'
import p15 from '../../assets/platform work/meta.png'
import p16 from '../../assets/platform work/nvidia.png'
import p17 from '../../assets/platform work/hugging.png'
import p18 from '../../assets/platform work/langchain.png'
import p19 from '../../assets/platform work/aws-design.png'
import p20 from '../../assets/platform work/apache_airflow.png'
import './style.css'



const Platforms = () => {
return (
    <div>
      <p className="text-center text-white mt-48 text-[30px]">
        <span className="comp_name">Platforms </span>
        We Work With
      </p>
      <div className="flex items-center justify-center
        flex-wrap img_cont mt-12 mx-20 overflow-hidden rounded-lg">

        <div id="marquee" className="marquee auto-cols-max 
          animate-marquee whitespace-nowrap">

          <div className="grid-item">
            <img src={p1} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p2} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p3} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p4} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p5} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p6} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p7} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p8} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p9} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p10} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p11} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p12} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p13} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p14} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p15} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p16} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p17} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p18} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p19} alt="" className="size-full" />
          </div>
          <div className="grid-item">
            <img src={p20} alt="" className="size-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platforms;
