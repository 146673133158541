import React from 'react'
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaInstagram, FaXTwitter, FaYoutube, FaLinkedinIn } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className='bg-[#1A1A1A] w-full p-1 md:p-12 mt-32 px-8 md:px-32 text-white'>
      <div className='w-full'>
        <div className='w-full flex items-start justify-between flex-wrap md:flex-row my-8'>
          <div className='flex items-start justify-center flex-col h-full w-full md:w-1/4'>
            <p className='mb-8 text-xs md:text-lg'>Chaitanya AI</p>
            <p className='text-[10px] md:text-xs'>ChaitanyaAi is more than an AI service provider; we're your dedicated partner, unlocking AI's transformative power. Empower data-driven decisions with our scalable Data Engineering, operationalized Machine Learning, and actionable Analytics solutions. Trust us to address your real-world challenges effectively.</p>
          </div>
          <div className='flex items-start md:items-center justify-center flex-col h-full w-full md:w-1/3'>
            <p className='mb-4 md:mb-8 mt-8 md:mt-0 text-xs md:text-lg'>Quick Links</p>
            <div className='text-xs flex flex-col'>
              <a href={`/`} className="my-1">
                Home
              </a>
              <a href={`/portfolio`} className="my-1">
                Portfolio
              </a>
              {/* <p className='my-1'>Home</p> */}
              {/* <p className='my-1'>Portfolio</p> */}
            </div>    
          </div>
          <div className='flex items-start justify-center flex-col h-full'>
            <p className='mb-4 md:mb-8 mt-8 md:mt-0 text-xs md:text-lg'>Contact Us</p>
            <div>
              <div className='text-xs flex items-center'><span className='text-2xl m-2'><MdOutlineEmail /></span> info@chaitanyaai.in</div>
              <div className='text-xs flex items-center'><span className='text-2xl mx-2'><IoCallOutline /></span> +91 99135 68622</div>
              <div className='text-xl flex m-2 mt-8'>

                <a href='https://www.instagram.com/joinchaitanyaai' 
                  className='border rounded-full p-2'>
                  <span className=''> 
                    <FaInstagram /></span>
                </a>
                <a href='https://x.com/ChaitanyaaiLlp' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaXTwitter /></span>
                </a>
                <a href='https://youtube.com/@chaitanya-ai' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaYoutube /></span>
                </a>
                <a href='https://www.linkedin.com/company/chaitanyaai/' 
                  className='border rounded-full p-2'>
                  <span className=''>
                    <FaLinkedinIn /></span>
                </a>

              </div>
            </div>    
          </div>
        </div>
        <hr />
        <div className='w-full flex items-center justify-between flex-row my-8 text-white text-[9px] md:text-xs'>
          <p className='w-1/3 md:w-full'>© 2024 Chaitanya-Ai LLP</p>
          <p className='w-1/4 md:w-1/5'>Privacy Policy • Terms & Conditions</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
