import React, { useState } from "react";
import './style.css'
import Detail from "./Detail";
import expimg1 from '../../assets/Our Expertise/bot.png'
import expimg2 from '../../assets/Our Expertise/data-engineer.png'
import expimg3 from '../../assets/Our Expertise/MLOps.png'
import expimg4 from '../../assets/Our Expertise/computer vision.png'
import expimg5 from '../../assets/Our Expertise/data-science.png'
import expimg6 from '../../assets/Our Expertise/solution engineering.png'

const data = [
  {
    title: "Generative AI",
    desc: "Transform your business with the power of Generative AI technologies. Our expertise lies in productionizing Large Language Models into your applications. We specialize in guiding organizations through Generative AI Proof of Concepts (POCs) and seamlessly integrating Gen AI features into your products.",
    imgSrc: expimg1
  },
  {
    title: "Data Engineering",
    desc: "Revolutionize your organization's data management with our Data Engineering solutions. We consolidate scattered data silos into a centralized repository, providing a comprehensive 360-degree view of your entire organization. Our Data Engineering approach encompasses all facets of data transformation, spanning data lakes, data warehouses, and data marts.",
    imgSrc: expimg2
  },
  {
    title: "MLOps",
    desc: "Experience a seamless end-to-end machine learning development process with our MLOps solutions. We enable you to design, build, and manage reproducible, testable, and evolvable ML-powered software effortlessly.",
    imgSrc: expimg3
  },
  {
    title: "Computer Vision",
    desc: "Transform the way you interpret and analyze visual data. Our Computer Vision solutions enhance automation and decision-making processes, opening new avenues for innovation.",
    imgSrc: expimg4

  },
  {
    title: "Data Science",
    desc: "Unlock hidden patterns and glean valuable insights through Data Science and Advanced Analytics. Harness the power of both structured and unstructured data to make sense of your accumulated data archives, enabling you to solve intricate business challenges effectively.",
    imgSrc: expimg5
  },
  {
    title: "Solution Engineering",
    desc: "Our support services go beyond fixing issues. We proactively monitor, maintain, and optimize your AI systems, ensuring they operate at peak efficiency.",
    imgSrc: expimg6
  }
]

const Experties = () => {

  const [index, setIndex] = useState(0)

  return (
    <div className="">
      <p className="text-center text-white mt-48 mb-12 text-[30px]">
        Our <span className="comp_name">Expertise </span>
      </p>
      <div className="experties_data">
        <div className="title_e">
          <button onClick={() => setIndex(0)}>Generative AI</button>
          <button onClick={() => setIndex(1)}>Data Engineering</button>
          <button onClick={() => setIndex(2)}>MLOps</button>
          <button onClick={() => setIndex(3)}>Computer Vision</button>
          <button onClick={() => setIndex(4)}>Data Science</button>
          <button onClick={() => setIndex(5)}>Solution Engineering</button>
        </div>
        <div className="desc_e">
          <Detail data={data[index]} />
        </div>
      </div>
    </div>
  );
};

export default Experties;
