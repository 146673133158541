import React from "react";
import Header from "../component/Header";
import './style.css'
import Bg from "../component/BG/Bg";
import Footer from "../component/footer/Footer";
import Dropdown from "../component/Dropdown/Dropdown";
import rag from '../assets/rag.png'
import rag2 from '../assets/rag2.png'


const FAQs = [
  {
    title: " What techniques enhance LLM performance for specialized tasks? ",
    content: " Two prominent techniques for enhancing LLM performance are RAG and fine-tuning. Both methods help adapt LLMs to specific tasks and domains, improving their utility and accuracy. Both, RAG and fine tuning, require additional data to improve model performance. RAG uses external knowledge bases for retrieval, while fine-tuning uses labeled task-specific datasets. " 
  },
  {
    title: " What is Retrieval-Augmented Generation (RAG)? ",
    content: " RAG combines retrieval mechanisms with generative capabilities, allowing LLMs to access external knowledge bases to generate more accurate and contextually relevant responses. " 
  },
  {
    title: " What are common use cases for RAG? ",
    content: " Chatbots and AI Technical Support: Answer customer queries using organizational knowledge bases. Language Translation: Improve translations with domain-specific context. Medical Research: Provide up-to-date medical information. Educational Tools: Enhance learning with detailed, context-based answers. Legal Research and Document Review: Streamline legal document analysis and review " 
  },
]






const regfinetune = [
  {
    title: " Dynamic vs. Static ",
    content: " Generally, RAG performs exceptionally well in dynamic settings. This is because it regularly requests the most recent data from external knowledge bases without the need for frequent retraining. This ensures that the information generated by RAG-powered models is always up-todate. In contrast, fine-tuned LLMs often become static snapshots of their training datasets and easily become outdated in scenarios involving dynamic data. Additionally, fine-tuning isn’t always reliable since it sometimes doesn’t recall the knowledge it has acquired over time. " 
  },
  {
    title: " Architecture ",
    content: " RAG-powered LLMs have a hybrid architecture that combines a transformer-based model with an external knowledge base. Such a base allows you to efficiently retrieve relevant information from a knowledge source like company records, a set of fundamental documents, or a database. On the other hand, LLM fine-tuning often begins with a pre-trained LLM that is further trained on a task-specific dataset. In most cases, this architecture remains unchanged, with only adjustments made to the LLM’s weights or parameters to enhance its performance for the desired task. " 
  },
  {
    title: " Training data ",
    content: " RAG LLMs often rely on a combination of supervised and labeled data that demonstrates how to properly retrieve and utilize relevant external information. This explains why RAG-powered models can easily handle both retrieval and generation. In contrast, fine-tuned LLMs are trained using a task-specific dataset that mostly comprises labeled examples that match the desired task. Fine-tuned models are adapted to perform various NLP tasks, but aren’t built for information retrieval. " 
  },
  {
    title: " Model customization ",
    content: " RAG models mainly focus on information retrieval and may not automatically adapt their linguistic style or domain specialization based on the information obtained from an external knowledge base. This LLM learning technique excels at incorporating external relevant information but may not fully customize the LLM’s writing style or behavior. Fine-tuning, on the other hand, allows you to adjust an LLM’s behavior, domain-specific knowledge, and writing style to align with specific nuances, terminologies, or even tones. Basically, fine-tuning offers full model customization with respect to writing styles or expertise areas. " 
  },
  {
    title: " Hallucinations ",
    content: " Generally, RAG is less prone to hallucinations and biases since it grounds each response generated by an LLM in retrieved documents/evidence. Since it generates information from retrieved data, it becomes nearly impossible for it to come up with fabricated responses due to limited training data. Fine-tuning processes, on the other hand, can help reduce the risk of hallucinations by simply focusing on domain-specific data. However, fine-tuned models are still likely to generate inaccurate or erroneous responses when faced with unfamiliar queries. " 
  },
  {
    title: " Accuracy ",
    content: " Although RAG excels in generating up-to-date responses and minimizing the risk of hallucinations, its accuracy may vary based on the domain or task at hand. On the other hand, fine-tuning focuses on enhancing a model’s domain-specific understanding, which often leads to more accurate responses and predictions. " 
  },
  {
    title: " Transparency ",
    content: " RAG provides more transparency by splitting response generation into different stages, providing valuable information on data retrieval, and enhancing user trust in outputs. In contrast, fine-tuning functions like a black box, obscuring the reasoning behind its responses. " 
  },
  {
    title: " Cost ",
    content: " RAG requires less labeled data and resources than fine-tuning processes, making it less costly. Much of RAG expenses often go into setting up embedding and retrieval systems. In contrast, fine-tuning requires more labeled data, significant computational resources, and state-of-the-art hardware like high-performance GPUs or TPUs.[7] As a result, the overall cost of fine-tuning is relatively higher than RAG. " 
  },
  {
    title: " Complexity ",
    content: " RAG is relatively less complex as it only requires coding and architectural skills. Fine-tuning, on the other hand, is more complex as it requires an understanding of NLP, deep learning, model configuration, data reprocessing, and even evaluation " 
  },
]







const finetunedata = [
  {
    title: " Pre-Train an LLM ",
    content: " As you embark on a fine-tuning process, you must have a pretrained large language model. This means you need to gather large amounts of text and code and use it to train a general-purpose LLM. The main purpose of pre-training an LLM is to help it learn fundamental language patterns and relationships. Most importantly, a pre-trained LLM will have the generic knowledge and skills needed to perform various tasks, but it may not perform well on domain-specific tasks without further training. "
  },
  {
    title: " Prepare Task-Specific Data ",
    content: " Proceed to collect and label a smaller dataset relevant to your desired task. Such a dataset will provide the LLM with the various types of input and output it needs to learn from to perform the desired task. Once you’ve collected and labeled the task-specific data, split it into training, validation, and test sets. "
  },
  {
    title: " Reprocess the Data ",
    content: " The quality and quantity of your task-specific data will impact the effectiveness of your fine-tuning process. That said, start by converting your task-specific data into a form acceptable to your LLM. Once you’re done, identify and correct any errors present in your dataset. This may involve fixing inconsistent data, removing duplicates, and handling outliers. "
  },
  {
    title: " Adjust the Layers ",
    content: " A pre-trained LLM is usually made up of different layers, each responsible for extracting various parts of the input data. When fine-tuning a pre-trained LLM, some layers are not updated during the training process. Such layers represent general knowledge and should always remain unchanged. On the other hand, top/later layers are modified based on the task-specific data. This allows the large language model to adapt its knowledge for the desired task. "
  },
  {
    title: " Configure the Model ",
    content: " Once you’ve loaded the pre-trained model, you need to decide on the parameters for fine-tuning. These parameters may include learning rate, batch size, regularization techniques, and number of epochs based on domain knowledge. Adjusting these parameters is important for achieving your model’s best performance. "
  },
  {
    title: " Train the Model ",
    content: " Feed the reprocessed data to the pre-trained LLM as input and train it using a backpropagation algorithm. Since you’re training an already pre-trained model, you’ll often need fewer epochs than if you were training a model from scratch. The training process will help adjust the weights and biases of the fine-tuned layers to help minimize errors between the model’s predictions and the desired output. The training process is supposed to continue until the LLM achieves satisfactory performance on the desired task. It’s important to continuously monitor the model’s performance on the validation dataset to prevent overfitting and decide when to stop the training process or make necessary adjustments to the data. "
  },
  {
    title: " Evaluate Performance ",
    content: " Once the fine-tuning process is complete, evaluate the LLM’s performance on an unseen test dataset. This ensures the model has learned the desired patterns and can easily adapt to new patterns. You can use various metric scores to assess the performance of the LLM, including the BLEU score, ROUGE score, or even human evaluation. "
  },
  {
    title: " Iteration and Deployment ",
    content: "Based on the evaluation results, repeat the above steps to help improve the model’s performance. Once the LLM achieves satisfactory performance, it can be deployed to a specific application to perform the desired task. "
  },
]










const data = [
  {
    title: " Vector Database ",
    content: " To implement retrieval-augmented generation (RAG), you must start by inserting the internal dataset, then creating vectors from it, and finally store these vectors in a vector database. "
  },
  {
    title: " User Query/Input ",
    content: " Once the vector database is set up, the user provides a query or input to the large language model. In this case, an input could be a question that needs to be answered or a statement that you need to complete. "
  },
  {
    title: " Retrieval Component ",
    content: " Next, the LLM activates its retrieval component after it has received the query or input from the user. This component basically scans the vector database/large knowledge base like Wikipedia or web pages to find retrieved documents or chunks of information that are semantically similar to the user input or query. These chunks of relevant information are then used to give context to the large language model, enabling it to develop a more accurate, informative, and context-aware response. "
  },
  {
    title: " Knowledge Representation ",
    content: " In this step, the retrieved documents are processed and converted into a format understandable to the LLM. This may involve creating summaries, extracting key sentences, or even generating other representations to help capture the important information. "
  },
  {
    title: " Concatenation ",
    content: " Afterwards, the retrieved documents are linked to the original user query/input to provide additional context for generating response. "
  },
  {
    title: " Text Generation ",
    content: " The large language model uses its generator component to process the user input, retrieved documents, and concatenated query to generate a coherent, informative, and relevant response to the original user query/input. "
  },
]


const Ragfinetune = () => {
  return (
    <div className="w-full min-h-full">

      <Bg />

      <Header />

      <div className="lg:mx-[150px]">
      {/* <div className=""> */}

        <p className="text-center text-white mt-24 md:mt-24 text-2xl lg:text-[60px] md:text-[60px]">
          <span className="comp_name">RAG vs Fine-Tuning: </span>
          <br/><br/>Which is better for LLM applications?
        </p>
        <p className="text-center text-white font-[500] border-current border text-base font-poppins mt-16 leading-6 p-10 rounded-lg lg:p-10">
          In the rapidly evolving world of Large Language Models (LLMs), many developers are keen to integrate their capabilities into applications. However, a common hurdle arises when these out-of-the-box LLMs don’t meet expectations. This presents a pivotal question: How can we optimize their performance? It narrows down to two promising avenues: Should we employ Retrieval Augmented Generation (RAG) techniques or dive into model finetuning? <br/><br/>

          The emergence of Large Language Models (LLMs) has revolutionized the field of Natural Language Processing (NLP), demonstrating great capabilities in tasks such as text generation, language translation, question answering, and text summarization. As the popularity of LLMs continues to increase, many organizations and developers have started building advanced applications to harness the power of these models. <br/><br/>


          However, despite having great potential, even the most powerful pre-trained LLMs may not always meet your specific needs right out of the box. <br/><br/>

          Therefore, it’s necessary to adopt techniques that enable LLMs to specialize in specific domains and tasks, thus improving their overall effectiveness, accuracy, and versatility. Among the various techniques employed to enhance the performance of LLMs, two prominent approaches have emerged as frontrunners: Retrieval Augmented Generation (RAG) and fine-tuning.<br/>


        </p>

        <div className="border-white border-2 my-10"> </div>

        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full lg:w-7/12 text-white flex flex-col gap-8">
            <p className="font-bold text-[38px] leading-8">
              What is Retrieval-Augmented Generation? 
            </p>
            <ul className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                RAG, short for Retrieval-Augmented Generation, is an LLM learning technique that merges the retrieval mechanisms and generative capabilities to enhance the performance of large language models.
              </li>
              <li>
                Generally, retrieval models are good at searching vast external knowledge bases and finding relevant information for a given prompt. Generative models, on the other hand, excel at utilizing this information to generate new text.
              </li>
              <li>
                This hybrid approach often leads to the generation of accurate, informative, and in-context results as compared to using retrieval and generative models separately.
              </li>
            </ul>
          </div>
        </section>

        <section className="w-full flex flex-col justify-between mt-16 text-white gap-8 px-10 lg:px-0">
          <p className="font-bold text-[26px] leading-8">
            Retrieval Augmented Generation (RAG) amplifies language model responses by merging the prowess of data retrieval with text generation. Here’s how it functions:



          </p>
          <p className=" leading-6">
            <spam className='font-bold'>Retriever: </spam>
            When prompted with a query, say a question, this component combs through a vast database to pinpoint relevant excerpts or articles. These extracts then serve to contextualize the ensuing response.

            <br/>
            <br/>
            <spam className='font-bold'>Generator: </spam>
            Equipped with the retrieved context, a sophisticated language model crafts a comprehensive and precise answer.

            <br/>
            <br/>
          </p>
        </section>


        <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-fit'>
          <div className='flex flex-col lg:flex-row bg-black bg-opacity-35 border-red-900 border text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='text-center text-3xl px-10 lg:px-0'>
              <h1 className="text-red-50 font-bold mb-5"> Example: </h1>
              Suppose you ask, “What led to the fall of the Roman Empire?” A conventional language model might offer a broad overview. However, with RAG, the retriever might first fetch specific articles discussing economic challenges, military defeats, or political corruption of that era. The generator then weaves an answer incorporating these specifics, offering a more nuanced explanation about internal decay, external invasions, and socio-economic factors contributing to the empire’s decline
            </div>
          </div>
        </section>



        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">
              How Retrieval-Augmented Generation (RAG) Works?
            </p>
            <div className="h-auto lg:w-[800px] sm:w-auto md:w-auto border-white border mx-auto">
              <img src={rag} className='size-full' alt="ragg" />
            </div>
            <div className='w-fit'>
              {data && data.map(item => (
                <Dropdown title={item.title} content={item.content} />
              ))}
            </div>
          </div>
        </section>




        {/* <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-52'> */}
        {/*   <div className='flex flex-col lg:flex-row bg-black bg-opacity-25 text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'> */}
        {/*     <div className='font-bold text-4xl px-10 lg:px-0'> */}
        {/*       Examples of AI in e-commerce */}
        {/*     </div> */}
        {/*     <div className='w-full lg:w-3/4 px-10 lg:px-0'> */}
        {/*       AI is currently used in organizations of all sizes—from small businesses to large enterprises. Here are a few examples of companies using AI today to enhance the online shopping experience. */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </section> */}




        <section className="w-full flex justify-between mt-16 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-10 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <h1 className="text-3xl font-bold">
              RAG Use Cases :- 
            </h1>
            <ol className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                Educational Tools
              </li>
              <li>
                Chatbots and AI Technical Support
              </li>
              <li>
                Legal Research and Document Review
              </li>
              <li>
                Language Translation
              </li>
              <li>
                Medical Research
              </li>
            </ol>
          </div>


          <div className="w-full text-white flex flex-col gap-8">
            <h1 className="text-3xl font-bold">
              Benefits of RAG
            </h1>
            <ol className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                Cost-effectiveness
              </li>
              <li>
                Improved accuracy
              </li>
              <li>
                Interpretability and transparency
              </li>
              <li>
                Reduced hallucinations and biases
              </li>
              <li>
                Adaptability to new data
              </li>
            </ol>
          </div>

        </section>




        <div className="border-white border-2 my-10"> </div>





        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full lg:w-7/12 text-white flex flex-col gap-8">
            <p className="font-bold text-[38px] leading-8">
              What is Fine-Tuning?
            </p>
          </div>
        </section>

        <section className="w-full flex flex-col justify-between mt-16 text-white gap-8 px-10 lg:px-0">
          <p className=" leading-6">
            Fine-tuning refers to the process of training a large language model that has already been pre-trained to help improve its overall performance. By fine-tuning, you’re simply adjusting the model’s weights, architecture, or parameters based on the available labeled data, making it more tailored to perform specialized tasks.
            <br/>
            <br/>
            <spam className='font-bold'>Finetuning: </spam>
            involves refining an existing model for a specific task or dataset. Instead of starting from scratch, which can be lengthy and expensive, finetuning leverages the model’s pre-existing knowledge from its earlier broad training. This method is especially effective for tasks that are somewhat different from the original training context.

            <br/>
            <br/>
          </p>
        </section>


        <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-fit'>
          <div className='flex flex-col lg:flex-row bg-black bg-opacity-35 border-red-900 border text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='text-center text-3xl px-10 lg:px-0'>
              <h1 className="text-red-50 font-bold mb-5"> Example: </h1>
              Imagine you have a general language model trained to understand and generate English text. Now, you want to specialize this model for medical terminology. Rather than training a whole new model on medical data alone (which can be expensive and time-consuming), you can finetune the general model using a smaller set of medical texts. The result? The model will still have its vast general knowledge of English but will now be more adept at understanding and producing medical terms.
            </div>
          </div>
        </section>



        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">
              How Retrieval-Augmented Generation (RAG) Works?
            </p>
            <div className="h-auto lg:w-[800px] sm:w-auto md:w-auto border-white border mx-auto">
              <img src={rag2} className='size-full' alt="ragg" />
            </div>
            <div className='w-fit'>
              {finetunedata && finetunedata.map(item => (
                <Dropdown title={item.title} content={item.content} />
              ))}
            </div>
          </div>
        </section>




        {/* <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-52'> */}
        {/*   <div className='flex flex-col lg:flex-row bg-black bg-opacity-25 text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'> */}
        {/*     <div className='font-bold text-4xl px-10 lg:px-0'> */}
        {/*       Examples of AI in e-commerce */}
        {/*     </div> */}
        {/*     <div className='w-full lg:w-3/4 px-10 lg:px-0'> */}
        {/*       AI is currently used in organizations of all sizes—from small businesses to large enterprises. Here are a few examples of companies using AI today to enhance the online shopping experience. */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </section> */}






        <section className="w-full flex justify-between mt-16 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-10 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <h1 className="text-3xl font-bold">
              Educational Tools
            </h1>
            <ol className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                Sentiment analysis
              </li>
              <li>
                Named-entity recognition(NER)
              </li>
              <li>
                Personalized content recommendation
              </li>
            </ol>
          </div>


          <div className="w-full text-white flex flex-col gap-8">
            <h1 className="text-3xl font-bold">
              Benefits of LLM fine-tuning
            </h1>
            <ol className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                Less training data required
              </li>
              <li>
                Improved accuracy
              </li>
              <li>
                Increased robustness
              </li>
            </ol>
          </div>

        </section>



        <section className='w-full bg-gradient-to-r from-[#00FFB2] to-[#6D7DFF] mt-44 p-[2px] rounded-xl h-full lg:h-52'>
          <div className='flex flex-col lg:flex-row bg-black text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-4xl px-10 lg:px-0'>
              RAG vs. Fine-tuning
            </div>
            <div className='w-full lg:w-3/4 px-10 lg:px-0'>
              RAG vs. Fine-tuning
              Next, we’ll thoroughly explore the nuances of both Retrieval Augmented Generation (RAG) and
              model finetuning. By evaluating them from various angles, this in-depth comparison seeks to
              assist you in choosing the best technological strategy to meet your AI and business goals. We’ll
              <br/>
              <br/>
              begin by assessing the requirements for LLM projects.
              Retrieval-Augmented Generation (RAG) and fine-tuning are two completely different
              approaches to building and using Large Language Models (LLMs). That said, there are various
              factors you need to consider when choosing between RAG and LLM fine-tuning as your
              preferred LLM learning technique.

            </div>
          </div>
        </section>




        <section className="w-full flex justify-between flex-col-reverse mt-28 lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">
              They include:
            </p>
            <div className='w-fit'>
              {regfinetune && regfinetune.map(item => (
                <Dropdown title={item.title} content={item.content} />
              ))}
            </div>
          </div>
        </section>


        <section className='w-full bg-gradient-to-r from-[#00FFB2] to-[#6D7DFF] mt-20 p-[2px] rounded-xl'> </section>



        <p className="text-center text-white mt-24">
          <span className="comp_name text-center text-white mt-24 text-5xl">
            RAG vs. fine-tuning: Final thoughts
          </span>
          <br/><br/>
          The main differences in RAG vs fine tuning lie in complexity, architectural design, use cases, and model customization. That said, the choice between the two LLM learning approaches should be based on the available resources, the need for customization, and the nature of the data. This way, you can tailor your preferred LLM learning technique to their specific needs.
          <br/><br/>
          It’s important to note that RAG and fine-tuning are not rivals. Although both LLM learning approaches have their strengths and weaknesses, combining them may be the best solution for your organization. Fine-tuning a model for a particular task and then enhancing its performance with retrieval-based mechanisms may be exactly what you need for a successful LLM project.
        </p>


        <section className='w-full bg-gradient-to-r from-[#6D7DFF] to-[#00FFB2] mt-20 p-[2px] rounded-xl'> </section>


        <section className="w-full flex justify-between flex-col-reverse mt-28 lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">
              RAG and fine tunning – FAQs
            </p>
            <div className='w-fit'>
              {FAQs && FAQs.map(item => (
                <Dropdown title={item.title} content={item.content} />
              ))}
            </div>
          </div>
        </section>



        {/* <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-fit'> */}
        {/*   <div className='flex flex-col lg:flex-row bg-black bg-opacity-35 text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'> */}
        {/*     <div className='text-justify px-10 lg:px-0'> */}
        {/*       <h1 className="text-red-50 font-bold mb-5"> Reference Links: </h1> */}
        {/*       <ol> */}
        {/*         <li> */}
        {/*           <a className="comp_name" href="https://bit.ly/3ROcImN"> */}
        {/*             Infoworld.com. Vector Database in LLMs and Search. */}                  
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href="https://bit.ly/3ROcKuV"> */}
        {/*         Ai.plainenglish.io. Building a Question Answering System Using LLM. */}
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href="https://ca.indeed.com/career-advice/careerdevelopment/domain-knowledge"> */}
        {/*         Ca.indeed.com. Domain Knowledge. */}
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href="https://towardsdatascience.com/train-validation-and-test-sets-72cb40cba9e7"> */}
        {/*         Towardsdatascience.com. Train Validation and Test Sets. */}
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href=" https://medium.com/@sthanikamsanthosh1994/understanding-bleu-and-rouge-score-for-nlpevaluation-1ab334ecadcb"> */}
        {/*         Medium.com. Understanding BLEU and ROUGE for NLP Evaluation. */}
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href=" https://bit.ly/3tj1a1n"> */}
        {/*         Learn.Microsoft.com. LLM AI Tokens. */} 
        {/*           </a> */}
        {/*         </li> */}

        {/*         <li> */}
        {/*           <a className="comp_name" href="https://openmetal.io/docs/productguides/private-cloud/tpu-vs-gpu-pros-and-cons/"> */}
        {/*         Openmetal.io. TPU vs GPU: Pros and Cons. */}
        {/*           </a> */}
        {/*         </li> */}

        {/*       </ol> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </section> */}






      </div>

      <Footer />

    </div>
  );
};

export default Ragfinetune;
