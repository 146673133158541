import React, { useState } from "react";
import Header from "../component/Header";
import './style.css'
import Bg from "../component/BG/Bg";
import Footer from "../component/footer/Footer";
import Newsletter from "../component/CaseStudies/Newsletter";
import grp from "../assets/Case studies/ppl.png"
import rag from "../assets/Case studies/robots.png"
import ai from "../assets/Case studies/ecom.png"
import bulb from "../assets/Case studies/insightbulb.png"
import Cards from "../component/CaseStudies/Cards";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const data = [
  {
    content:
    "In the evolving world of Large Language Models (LLMs), optimizing their performance is crucial. Two primary techniques have emerged: Retrieval Augmented Generation (RAG) and fine-tuning. RAG enhances models by integrating external data sources, improving accuracy and context relevance. Fine-tuning, on the other hand, adapts pre-trained models to specific tasks or domains, enhancing their effectiveness and versatility. Both methods offer unique advantages for specialized applications. ",
    title: "RAG vs Fine-tuning",
    profile: rag,
    link: `ragfinetune`,
  },
  {
    content:
    " AI in eCommerce leverages advanced algorithms to enhance the online retail experience. It streamlines operations, personalizes product recommendations, automates customer service, and prevents fraud. Companies like Amazon and Shopify, along with startups like Urbanlyfe, utilize AI tools like Amazon Lex, AWS, and Shopify Magic to improve customer experience, user targeting, and product selection, driving more efficient and personalized eCommerce solutions. ",
    title: " AI in E-commerce ",
    profile: ai,
    link: `aICommerce`,
  },
];


const CaseStudies = () => {

  const [formData, setFormData] = useState({
    email: '',
  });


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs .sendForm('service_irekndn', 
      'template_pg4smwc', 
      form.current, { publicKey: 'SZnjQEdlIN3c4GNEB', })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


  return (
    <div className="w-full h-full">

      <Bg />

      <Header />

      <p className="text-center text-white my-24 md:mt-48 font-bold text-[28px] 
        md:text-[60px]">
        Case Studies <span className="comp_name">ChaitanyaAi</span><br />
      </p>


      <div className='flex flex-col lg:px-60
        lg:flex-row mx-8 my-12 lg:p-4 rounded-xl'>

        <div className="mx-8 flex">

          <h1 className='flex text-white font-bold text-center text-3xl mb-8 
            lg:text-5xl rounded-xl my-auto mx-auto'>
            AI Excellence in Action: Case Studies from ChaitanyaAI
          </h1>

        </div>

        <img className='flex rounded-xl'
          src={grp} alt="" />


      </div>


      <div className="">
        {data.map((item) => (
          <Cards data={item} />
        ))}

      </div>



      <div className='flex flex-col lg:px-60
        lg:flex-row mx-8 my-12 lg:p-4 rounded-xl'>

        <div className="lg:my-8 lg:mx-12">
          <a className='text-center my-5 font-bold'>
            <button className='text-white text-4xl rounded-xl p-2'>
              Read Our Insights and Innovations

            </button> 

          </a>

          <p className='text-white p-2'>
            Dive into the world of AI through our blog. Gain valuable insights, stay updated on industry trends, and explore the latest innovations in artificial intelligence.
          </p>

        </div>

        <img className='flex rounded-xl'
          src={bulb} alt="" />

      </div>

      <Newsletter />

      {/* <section className="border-blue-800 p-8 border lg:p-8 lg:m-20 lg:mx-60 */} 
      {/*   rounded-3xl"> */}
      {/*   <form */} 
      {/*     className="bg-white lg:gap-8 rounded-[50px] lg:grid lg:p-8 */} 
      {/*     lg:grid-cols-12 p-6" */}
      {/*     ref={form} */} 
      {/*     onSubmit={sendEmail}> */}

      {/*     <div className="absolute right-52 bg-green-400 h-[200px] w-[400px] rounded-l-full"> */}
      {/*     </div> */}

      {/*     <div className="absolute left-52 top-14 bg-blue-400 h-[200px] w-[200px] */} 
      {/*       rounded-bl-[100px] z-10"> */}
      {/*     </div> */}

      {/*     <p className="text-[#00FFB2] text-2xl lg:text-4xl font-bold lg:col-span-1"> */}

      {/*       Signup to our Newsletter! */}
      {/*     </p> */}
      {/*     <div className="lg:col-span-3"> */}

      {/*     </div> */}

      {/*     <div className="col-span-4 flex my-auto"> */}
      {/*       <input */}
      {/*         className="w-full px-3 py-2 bg-transparent border-b my-4 */}
      {/*         border-white focus:outline-none focus:border-blue-500 */} 
      {/*         focus:bg-white focus:text-black text-white placeholder-gray-300" */}
      {/*         id="Email" */}
      {/*         name="Email" */}
      {/*         type="email" */}
      {/*         autoComplete="email" */}
      {/*         placeholder="your@email.com" */}
      {/*         onChange={(e) => setFormData({ ...formData, email: e.target.value })} */}
      {/*         required */}
      {/*       /> */}
      {/*     </div> */}

      {/*     <button */}
      {/*       type="submit" */} 
      {/*       value="Send" */} 
      {/*       className="col-span-4 h-12 text-center flex */} 
      {/*       my-auto w-full bg-gradient-to-r from-[#6D7DFF80] */} 
      {/*       to-[#00FFB2] text-white font-bold py-2 px-4 hover:opacity-60 */}
      {/*       focus:outline-none focus:ring-2 focus:ring-blue-500 active:bg-white */}
      {/*       focus:ring-opacity-50 rounded-xl transition-colors duration-200" */}
      {/*     > */}
      {/*       <p className="flex my-auto"> */}
      {/*         Submit */}
      {/*       </p> */}
      {/*     </button> */}

      {/*   </form> */}

      {/* </section> */}

      <Footer />

    </div>
  );
};

export default CaseStudies;
