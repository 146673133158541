import React from 'react'
import './urbanlyfe.css';
// import logo from '../assets/urbanlyfeassets/logo.png';
import greenbrush from '../assets/urbanlyfeassets/brushgreen.png';
import bluebrush from '../assets/urbanlyfeassets/brushblue.png';
import pinkbrush from '../assets/urbanlyfeassets/brushpink.png';
import screens from '../assets/urbanlyfeassets/Digital Device Mockup Freebie Vol.2 1.png'
// import poly from '../assets/urbanlyfeassets/Rectangle 19.png'
import phone from '../assets/urbanlyfeassets/phone.png'
import bpgrad from '../assets/urbanlyfeassets/bluepinkgrad.png'
import pwgrad from '../assets/urbanlyfeassets/pinkwhitgrad.png'
// import dotline from '../assets/urbanlyfeassets/Line 3.png'
import grp1 from '../assets/urbanlyfeassets/Group1.png'
import grp2 from '../assets/urbanlyfeassets/Group2.png'

import grp1p1 from '../assets/urbanlyfeassets/grp1/1.png';
import grp1p2 from '../assets/urbanlyfeassets/grp1/2.png';
import grp1p3 from '../assets/urbanlyfeassets/grp1/3.png';
import grp1p4 from '../assets/urbanlyfeassets/grp1/4.png';
import grp1p5 from '../assets/urbanlyfeassets/grp1/5.png';

import grp2p1 from '../assets/urbanlyfeassets/grp2/1.png';
import grp2p2 from '../assets/urbanlyfeassets/grp2/2.png';
import grp2p3 from '../assets/urbanlyfeassets/grp2/3.png';
import grp2p4 from '../assets/urbanlyfeassets/grp2/4.png';

import phone1 from '../assets/urbanlyfeassets/phone1.png'
import phone2 from '../assets/urbanlyfeassets/phone2.png'
import phone3 from '../assets/urbanlyfeassets/phone3.png'
import phone4 from '../assets/urbanlyfeassets/phone4.png'
import phone5 from '../assets/urbanlyfeassets/phone5.png'
// import mailicon from '../assets/urbanlyfeassets/mail.png'
// import phoneicon from '../assets/urbanlyfeassets/iconphone.png'
// import socialsicon from '../assets/urbanlyfeassets/socialsicons.png'

import Header from '../component/Header';
import Footer from '../component/footer/Footer';


function Urbanlyfe() {
  return (
    <main className="bg-black h-screen">

      <Header />

      <body className='flex flex-col'>

        <section className='bg-black p-4 lg:px-48 pt-8 text-sky-50 top-0 
          z-10 flex flex-col h-screen bottom-80'>

          <div class="relative mt-28 mb-4">

            <img src={greenbrush} alt='alt text' 
              class="block mx-auto" />

            <h1 class="absolute inset-0 
              flex font-bold items-center justify-center 
              text-black text-5xl text-center 
              bg-opacity-0">
              Urbanlyfe
            </h1>

          </div>

          <h2 className='text-5xl text-center mb-8'>
            Towards Sustainable Living A Case Study
          </h2>

          <p className='text-white text-center mx-auto'>
            Urbanlyfe is a progressive ecommerce platform dedicated to organic products, offering seamless shopping and incentivizing environmentally-conscious consumerism through "green coins." It promotes ethical consumption, supports eco-friendly businesses, and aims to facilitate a greener lifestyle and sustainable economy by connecting consumers with organic suppliers.
          </p>

          <a href={`/contact`} className='mt-20 text-center text-black 
            border-4  rounded-sm -4 mx-auto 
            px-14 text-2xl py-5 font-bold primary'>
            <button>
              Get a free Quote
            </button>
          </a>
        </section>

        <section className='secondary h-screen -4 -red-500 flex justify-center'>

          <img src={screens} alt='alt text' class="absolute -mt-80 z-10 
            mx-auto mb-36" />


          <div className='rounded-2xl max-w-full lg:mx-48 lg:mb-44 my-auto 
            drop-shadow-2xl bg-white grid grid-flow-row lg:grid-flow-col p-2 m-2
            justify-between h-fit'>

            <div className='-red-500  mr-8 p-4 pr-16 pb-20 flex 
              flex-col text-black font-semibold text-2xl flex-grow'>
              Industry
              <span className='text-black font-bold text-3xl flex-grow'>
                Ecommerce Platform for Organic Product
              </span>
            </div>

            <div className='-red-500  p-4 mr-8 pr-16 pb-20 flex flex-col 
              text-black font-semibold text-2xl flex-grow'>
              Platform
              <span className='text-black font-bold text-3xl flex-grow'>
                Mobile App, Cloud
              </span>
            </div>

            <div className='-red-500  p-4 mr-8 pr-16 pb-20 flex flex-col 
              text-black font-semibold text-2xl flex-grow'>
              Project Length
              <span className='text-black font-bold text-3xl flex-grow'>
                7 Months
              </span>
            </div>

            <div className='-red-500  p-4 mr-8 pr-16 pb-20 flex flex-col 
              text-black font-semibold text-2xl flex-grow'>
              Service
              <span className='text-black font-bold text-3xl flex-grow'>
                Ai Consulting, End to End Ai Development
              </span>
            </div>

          </div>

        </section>

        <section className='h-fit -4 primary lg:flex-row
          -red-500 flex flex-col-reverse justify-center'>

          <div className='flex flex-col lg:mt-36 h-fit'>

            <h1 className='p-8 font-bold text-center lg:ml-24 text-6xl'>
              About the Project
            </h1>

            <p className='p-8 pb-0 mb-4 lg:mr-40 lg:ml-24 text-2xl'>
              Urbanlyfe is a progressive ecommerce platform dedicated to organic products. Offering a seamless shopping experience, users can conveniently purchase sustainable goods sourced from local organic retailers and trusted brands. With every transaction, users accumulate "green coins," incentivizing environmentally-conscious consumerism. Urbanlyfe fosters community engagement by promoting ethical consumption and supporting eco-friendly businesses. By bridging the gap between consumers and organic suppliers, Urbanlyfe strives to facilitate a greener lifestyle while fostering a sustainable economy.
            </p>

          </div>

          <img src={phone} alt='alt text' 
            class="lg:w-1/4 h-96 w-fit mx-auto mt-14 max-w-full lg:h-fit 
            lg:mt-20 lg:ml-20 lg:mr-24" />

        </section>


        <section className='secondary h-fit flex flex-col lg:flex-col'>

          <div className='grid grid-cols-2 lg:mx-44'>

            <div className='mt-24 relative col-span-2 text-center'>
              <img src={bluebrush} alt='alt text' class="block mx-auto" />
              <h1 class="absolute inset-0 
                flex font-bold items-center justify-center 
                text-black text-5xl text-center 
                bg-opacity-0 z-10">
                Challenges for Urbanlyfe may include
              </h1>
            </div> 

            <div className='flex flex-col p-4 lg:p-0 lg:flex-row col-span-2 
              lg:mx-8'>

              <div class="relative w-fit mx-auto mt-16 mb-4 flex">
                <img src={bpgrad} alt='alt text' class="block 
                  size-full mx-auto" />
                <h1 class="absolute inset-0 flex font-bold items-center 
                  justify-center text-black text-5xl text-center bg-opacity-0 
                  mx-auto mt-8 size-fit">
                  Supplier Network
                </h1>
                <p class="absolute inset-0 flex items-center px-6
                  justify-center text-black text-left bg-opacity-0 
                  mx-auto mt-24 size-fit text-2xl">
                  Maintaining a diverse and reliable network of organic retailers and brands could be challenging, especially in regions with limited availability of organic products. Ensuring consistent supply chain management and quality control is essential
                </p>
              </div>

              <div class="relative w-fit mx-auto mt-16 mb-4 flex ">
                <img src={bpgrad} alt='alt text' class=" block 
                  size-full mx-auto" />
                <h1 class="absolute inset-0 flex font-bold 
                  items-center justify-center 
                  text-black text-5xl text-center bg-opacity-0 
                  mx-auto mt-8  size-fit">
                  User Engagement
                </h1>
                <p class="absolute inset-0 flex items-center px-6 
                  justify-center text-black text-left bg-opacity-0 
                  mx-auto mt-24 size-fit text-2xl">
                  Encouraging users to adopt sustainable shopping habits and actively participate in the platform to earn "green coins" may require effective marketing strategies and ongoing community engagement efforts to sustain interest and participation.
                </p>
              </div>

            </div>


          </div>

          <div className=' grid grid-cols-2 lg:mx-44'>

            <div className='mt-24 relative col-span-2 text-center'>
              <img src={pinkbrush} alt='alt text' class="block mx-auto" />
              <h1 class="absolute inset-0 
                flex font-bold items-center justify-center 
                text-black text-5xl text-center 
                bg-opacity-0 z-10">
                Solution
              </h1>
            </div> 

            <div className='flex flex-col p-4 lg:p-0 lg:flex-row 
              col-span-2 lg:mx-8'>

              <div class="relative w-fit h-fit mx-auto mt-16 
                mb-4 flex">
                <img src={pwgrad}  alt='alt text' class=" block 
                  size-full mx-auto" />
                <h1 class="absolute inset-0 flex font-bold 
                  items-center justify-center 
                  text-black text-5xl text-center bg-opacity-0 
                  mx-auto mt-8 size-fit">
                  AI Consulting
                </h1>
                <p class="absolute inset-0 flex items-center px-6 
                  justify-center text-black text-left bg-opacity-0 
                  mx-auto mt-24 size-fit text-2xl">
                  ChaitanyaAi offers tailored AI consulting services to address Urbanlyfe's challenges, including optimizing supplier networks through predictive analytics and demand forecasting. Leveraging AI, we enhance decision-making processes to ensure a reliable and diverse inventory of organic products, overcoming supply chain obstacles efficiently
                </p>
              </div>

              <div class="relative w-fit mx-auto mt-32 lg:mt-16 mb-4 flex">
                <img src={pwgrad}  alt='alt text' class=" block 
                  size-full mx-auto" />
                <h1 class="absolute inset-0 flex font-bold 
                  items-center justify-center 
                  text-black text-4xl text-center bg-opacity-0 
                  mx-auto mt-8  size-fit">
                  End-to-End AI Development
                </h1>
                <p class="absolute inset-0 flex items-center px-6 
                  justify-center text-black text-left bg-opacity-0 
                  mx-auto mt-32 size-fit text-2xl">
                  ChaitanyaAi spearheads comprehensive AI development for Urbanlyfe, delivering solutions such as an AI-powered chatbot for seamless customer assistance, inventory management systems utilizing machine learning algorithms for efficient stock tracking, and sentiment analysis tools to gauge customer feedback and enhance user engagement. Our end-to-end AI solutions empower Urbanlyfe to overcome challenges and offer a superior shopping experience.
                </p>
              </div>
            </div>

          </div>

        </section>

        <section className='h-72 grad'> </section>



        <section className='h-fit flex flex-col ternary pt-20'>

          <h1 className='font-bold text-6xl mx-auto flex text-center 
            lg:mb-44 mb-96'>
            Problem Identified for Urbanlyfe
          </h1>

          <div className='absolute mt-[200px] md:mx-0 lg:mx-0 mx-4 w-fit h-fit 
            grid lg:grid-cols-6 lg:mt-28 lg:h-56 quarnary tlround 
            lg:w-[96rem] right-0'>

            <h1 className='font-bold lg:col-span-1 text-9xl pt-10 pl-14 
              opacity-40'>
              01
            </h1>

            <h1 className='font-bold lg:col-span-2 text-6xl pt-10 pl-10'>
              Limited Accessibility
            </h1>

            <p className='lg:col-span-3 text-2xl py-5 lg:pt-10 pl-10 pr-16'>
              Despite the platform's focus on promoting sustainable products, Urbanlyfe may face challenges in reaching a wider audience due to limited availability of organic retail stores in certain areas.
            </p>

          </div>





          <div className='absolute mt-[800px] md:mx-0 lg:mx-0 mx-4 w-fit h-fit grid lg:grid-cols-6 
            lg:mt-96 lg:h-56 pantary trround lg:w-[96rem] left-0'>

            <p className='col-span-3 text-2xl lg:text-right py-5 lg:pt-10 pl-10 pr-16'>
              While Urbanlyfe encourages eco-friendly shopping habits, there may be a lack of awareness among consumers about the benefits of organic products and the importance of supporting sustainable brands, potentially hindering adoption of the platform.
            </p>

            <h1 className='font-bold col-span-2 text-6xl py-10 pl-10'>
              Consumer Awareness
            </h1>

            <h1 className='font-bold col-span-1 text-9xl my-auto
              lg:pt-10 lg:pl-14 opacity-40'>
              02
            </h1>

          </div>




          <h1 className='font-bold opacity-0 pt-[36rem] mx-auto flex text-center'>
            .
          </h1>

        </section>


        <section className='sanary flex flex-col'>

          <div className='flex grad2 w-full h-auto'>
            <h1 className='font-bold text-5xl pt-24 pb-14 mx-auto lg:mt-0 
              mt-60'>
              How it Works
            </h1>
          </div>


          <div className='flex flex-col '>

            <div className='flex flex-row  my-14 '>
              <div className='h-1 w-24 my-auto bg-black '> </div>
              <div className='size-16 rounded-full text-current
                justify-center items-center my-auto bg-black 
                flex'>
                <h1 className='font-bold text-4xl text-green-100 mx-auto 
                  my-auto flex'>
                  1
                </h1>
              </div>
              <h1 className='my-auto font-bold text-4xl ml-24 '>
                Shop at near by Organic Store
              </h1>
            </div>

            <div className='flex flex-col lg:hidden mx-auto'>
              <img src={grp1p1} alt='alt text' class="w-[600px] h-full" />
              <img src={grp1p2} alt='alt text' class="w-[600px] h-full" />
              <img src={grp1p3} alt='alt text' class="w-[600px] h-full px-4" />
              <img src={grp1p4} alt='alt text' class="w-[600px] h-full" />
              <img src={grp1p5} alt='alt text' class="w-[600px] h-full px-4" />
            </div>

            <img src={grp1} alt='alt text' class="hidden lg:block w-full 
              p-64 pb-32 pt-0 " />

          </div>


          <div className='flex flex-col mt-12'>

            <div className='flex flex-row  my-10 mb-8 '>
              <div className='h-1 w-24 my-auto bg-black '> </div>
              <div className='size-16 rounded-full text-current
                justify-center items-center my-auto bg-black 
                flex'>
                <h1 className='font-bold text-4xl text-green-100 mx-auto 
                  my-auto flex'>
                  2
                </h1>
              </div>
              <h1 className='my-auto font-bold text-4xl ml-24 '>
                Shop at near by Organic Store
              </h1>
            </div>

            <div className='flex flex-col lg:hidden mx-auto'>
              <img src={grp2p1} alt='alt text' class="w-[600px] h-full" />
              <img src={grp2p2} alt='alt text' class="w-[600px] h-full" />
              <img src={grp2p3} alt='alt text' class="w-[600px] h-full px-4" />
              <img src={grp2p4} alt='alt text' class="w-[600px] h-full" />
            </div>
            <img src={grp2} alt='alt text' class="hidden lg:block w-full 
              p-64 pb-20 pt-0 " />

          </div>

        </section>








        <section className='h-fit'>

          <div className='flex flex-col justify-center lg:mx-56'>

            <h1 className='my-36 font-bold text-6xl mx-auto'>
              App Screens
            </h1>

            <div className='grid lg:grid-cols-6 gap-8'>
              <img src={phone1} alt='alt text' class="mx-auto rounded-2xl lg:col-span-2" />
              <img src={phone2} alt='alt text' class="mx-auto rounded-2xl lg:col-span-2" />
              <img src={phone3} alt='alt text' class="mx-auto rounded-2xl lg:col-span-2" />
              <img src={phone4} alt='alt text' class="mx-auto rounded-2xl lg:col-span-3" />
              <img src={phone5} alt='alt text' class="mx-auto rounded-2xl lg:col-span-3" />
            </div>

          </div>
        </section>


        <section className='p-4 lg:px-48 z-10 flex flex-col h-fit'>

          <div class="relative mt-28 mb-4">

            <img src={greenbrush} alt='alt text' class="block 
              mx-auto  w-[40rem]" />

            <h1 class="absolute inset-0 flex font-bold items-center 
              justify-center text-black text-5xl text-center bg-opacity-0">
              The Conclusion
            </h1>

          </div>

          <p className='text-black text-2xl opacity-80 text-center 
            mx-auto lg:w-[76rem] '>
            ChaitanyaAi is dedicated to empowering Urbanlyfe with cutting-edge AI solutions tailored to address its unique challenges. By providing AI consulting and end-to-end development services, we enable Urbanlyfe to optimize supplier networks, streamline inventory management, and enhance customer engagement through sentiment analysis. Our collaborative efforts ensure Urbanlyfe remains at the forefront of the organic products market, offering users a seamless shopping experience while promoting sustainability and community engagement. Together, we pave the way for a greener and more prosperous future
          </p>



        {/*   {/1* <h1 className='mt-48 mb-28 text-6xl font-bold text-black mx-auto'> *1/} */}
        {/*   {/1*   More Case Studies *1/} */}
        {/*   {/1* </h1> *1/} */}

        {/*   {/1* <div className='grid mb-36 grid-cols-2 gap-40 mx-auto'> *1/} */}

        {/*   {/1*   <div className='flex size-fit flex-col border-4 *1/} */} 
        {/*   {/1*     border-black'> *1/} */}
        {/*   {/1*     <div className='bg-gray-400 w-[36rem] h-[40rem]'> *1/} */} 
        {/*   {/1*       {/2* IMAGE GOES HERE, REPLACE THE ABOVE DIV *2/} *1/} */}
        {/*   {/1*     </div> *1/} */}
        {/*   {/1*     <h1 className='p-4 text-4xl font-bold w-[36rem]'> *1/} */}
        {/*   {/1*       Room Reveal - Wallpaper Made Easy *1/} */}
        {/*   {/1*     </h1> *1/} */}

        {/*   {/1*     <p className='p-4 pt-1 text-2xl text-black w-[36rem]'> *1/} */}
        {/*   {/1*       Introducing Room Reveal, a dynamic web platform that revolutionizes wallpaper visualization. Seamlessly blending user-generated content with pre-existing designs, Room Reveal offers endless possibilities for transforming living spaces. *1/} */}
        {/*   {/1*     </p> *1/} */}

        {/*   {/1*   </div> *1/} */}

        {/*   {/1*   <div className='flex size-fit flex-col border-4 *1/} */} 
        {/*   {/1*     border-black'> *1/} */}
        {/*   {/1*     <div className='bg-gray-400 w-[36rem] h-[40rem]'> *1/} */} 
        {/*   {/1*       {/2* IMAGE GOES HERE, REPLACE THE ABOVE DIV *2/} *1/} */}
        {/*   {/1*     </div> *1/} */}
        {/*   {/1*     <h1 className='p-4 text-4xl font-bold w-[36rem]'> *1/} */}
        {/*   {/1*       Urbanlyfe - Towards Sustainable Living A Case Study *1/} */}
        {/*   {/1*     </h1> *1/} */}

        {/*   {/1*     <p className='p-4 pt-1 text-2xl text-black w-[36rem]'> *1/} */}
        {/*   {/1*       Urbanlyfe is a progressive ecommerce platform dedicated to organic products. Offering a seamless shopping experience, users can conveniently purchase sustainable goods sourced from local organic retailers and trusted brands *1/} */}
        {/*   {/1*     </p> *1/} */}

        {/*   {/1*   </div> *1/} */}

        {/*   {/1* </div> *1/} */}
        </section>

      </body>

      <Footer/>

    </main>
  );}

export default Urbanlyfe;
