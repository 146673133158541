import React from 'react'
import './style.css'
import { FaChevronDown } from "react-icons/fa6";
import './style.css'

const Dropdown = ({title, content}) => {
  return (
    <div className='dropdown_container'>
      <div className='dropdown_title'>
        <p className='text-white'>
            {title}
        </p>
        <div className='icon text-white'>
            <FaChevronDown />
        </div>
      </div>
      <div className='dropdown_content'>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default Dropdown
