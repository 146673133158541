import React from 'react'

const Cards = ({data}) => {
  return (
    <>

      <div className='flex flex-col lg:px-60
        lg:flex-row mx-8 my-12 lgi:p-4 rounded-xl'>

        <a href={data.link} className='text-center'>
          <img className='flex border-[#6D7DFf50] border rounded-xl'
            src={data.profile} alt="" />
        </a>

        <div className="lg:my-8 lg:mx-12">
          <a href={data.link} className='text-center my-5 font-bold'>
            <button className='text-white text-4xl border-[#00FFB250] border 
              rounded-xl p-2 hover:bg-[#00FFB250]'>
              {data.title}
            </button>
          </a>

          <p className='text-white p-2'>
            {data.content}
          </p>
        </div>

      </div>


      {/* <div className='flex flex-col lg:flex-row mx-8 my-12 p-4 rounded-xl'> */}
      {/*   <a href='' className='text-center'> */}
      {/*     <img className='flex h-96 w-fit border-[#6D7DFf50] border rounded-xl' */}
      {/*       src={data.profile} alt="" /> */}
      {/*   </a> */}

      {/*   <a href='' className='text-center my-5'> */}
      {/*     <button className='text-white text-3xl border-[#00FFB250] border */} 
      {/*       rounded-xl p-2 hover:bg-[#00FFB250]'> */}
      {/*       {data.title} */}
      {/*     </button> */}
      {/*   </a> */}
      {/*   <p className='text-white'> */}
      {/*     {data.content} */}
      {/*   </p> */}
      {/* </div> */}

    </>
  )
}

export default Cards
