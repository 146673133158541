import React from "react";
import "./style.css";
import Urbanlyfe from "../../assets/partnerwith/logourbanlyfe.png"
import Roomreveal from "../../assets/partnerwith/Logo main.png"
import Immersio from "../../assets/partnerwith/immersio.png"
import Einfochips from "../../assets/partnerwith/einfochips.png"
import Humanify from "../../assets/partnerwith/humanify.png"
const Partners = () => {
  return (
    <div className="w-full">
      <p className="text-center text-white mt-48 text-[30px]">
        We <span className="comp_name">Partner </span>
        With
      </p>
      <div className="mt-12 flex items-center justify-center flex-row w-full flex-wrap">
        <div className="boxes">
          <img src={Urbanlyfe} alt="Urbanlyfe" />
        </div>
        <div className="boxes">
          <img src={Roomreveal} alt="Roomreveal" />
        </div>
        <div className="boxes">
          <img src={Immersio} alt="Immersio" />
        </div>
        <div className="boxes">
          <img src={Einfochips} alt="Einfochips" />
        </div>
        <div className="boxes">
          <img src={Humanify} alt="Humanify" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
