import React from 'react'
import './Style.css'
import air from '../../assets/airplane.png'

const GetInTouch = () => {
  return (
    <div className='get_touch'>
      <hr />
      <div className='flex justify-center md:justify-between items-center flex-wrap w-full my-8'>
        <div className='sentance1'>
          <p>" Get In</p>
          <p>Touch</p>
        </div>
        <div>
          <img src={air} alt="" />
        </div>
        <a href="https://calendly.com/chaitanyaaillp/introduction-call" target='_blank' rel="noopener noreferrer">
          <button>
            Talk With Us
          </button>
        </a>
      </div>
      <hr />
    </div>
  )
}

export default GetInTouch
