import React from "react";
import H1 from "../public/H-1.png";
import H2 from "../public/H-2.png";
import H3 from "../public/H-3.png";
import H4 from "../public/H-4.png";

const HighlightedF = () => {
  return (
    <div className="flex flex-col items-center gap-5 mt-28 lg:mt-[10%]">
      <h1 className="lg:w-[765.28px] text-center font-black text-[45.75px]">
        Highlighted Features
      </h1>
      <div className="grid grid-cols-1 lg:mx-0 mx-4 lg:grid-cols-2 justify-center gap-6">

        <div className="card bg-black text-white p-5 shadow-xl flex flex-col h-[202px] lg:w-[586px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={H1}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[18.91px] w-[301px]">
            Instant Wallpaper Visualization
          </h3>
          <p className=" text-[#636B76] font-normal text-[14px] lg:w-[504.2px]">
            See the walls come alive in seconds with our wallpaper visualizer,
            allowing you to experiment with designs effortlessly.
          </p>
        </div>
        <div className="card bg-black text-white p-5 shadow-xl flex flex-col h-[202px] lg:w-[586px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={H2}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[18.91px] w-[301px]">
          Enhance Customer Experience
          </h3>
          <p className=" text-[#636B76] font-normal text-[14px] lg:w-[504.2px]">Transform your customers' shopping experience with interactive design tools, making wallpaper selection engaging and fun
          </p>
        </div>
        <div className="card bg-black text-white p-5 shadow-xl flex flex-col h-[202px] lg:w-[586px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={H3}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[18.91px] w-[301px]">
          Edge over Competitors
          </h3>
          <p className=" text-[#636B76] font-normal text-[14px] lg:w-[504.2px]">Stand out in the marketplace by offering an innovative, immersive shopping experience that sets you apart from competitors.
          </p>
        </div>
        <div className="card bg-black text-white p-5 shadow-xl flex flex-col h-[202px] lg:w-[586px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={H4}
            width={32}
            height={32}
            alt=""
          />
          <h3 className=" font-bold text-[18.91px] w-[301px]">
          Better Decision Making
          </h3>
          <p className=" text-[#636B76] font-normal text-[14px] lg:w-[504.2px]">Enable better decision-making with a tool that visualizes different wallpapers in real settings, ensuring choices are made with confidence
          </p>
        </div>

      </div>
    </div>
  );
};

export default HighlightedF;
