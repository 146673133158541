import React from 'react'
import value1 from '../../assets/company/value1.svg';
import value2 from '../../assets/company/value2.svg';
import value3 from '../../assets/company/value3.svg';
import value4 from '../../assets/company/value4.svg';

const values = [
  {
    title: 'Ownership and Accountability',
    description: 'We take ownership and hold ourselves accountable for every action.',
    img: value1
  },
  {
    title: 'Exceptional Results',
    description: 'We deliver results that "wow" our clients, refusing to settle for mediocrity.',
    img: value2
  },
  {
    title: 'Continuous Learning',
    description: 'Embracing growth, we are always striving for excellence and unique approaches to problem-solving.',
    img: value3
  },
  {
    title: 'Collaborative Team',
    description: 'We collaborate closely with our clients, standing out from the crowd with our dedication and client-centric approach.',
    img: value4
  },
];


const CompanyValues = () => {
  return (
    <section className='w-full my-28 flex flex-col px-10 lg:px-0'>
      <h3 className=' text-[26px] leading-8 font-inter font-bold text-white '>
        Our values define us:
      </h3>
      <div className='mt-10 lg:mt-20'>
        <div className='flex flex-col relative gap-5 lg:gap-16 lg:px-16'>
          {
            values.map((value, index) => (
              <div key={index} className={`w-full flex ${index % 2 == 0 ? 'flex-row' : 'flex-row-reverse'} gap-2 items-center mt-5`}>
                <div className='w-full lg:w-2/5 text-white flex flex-col justify-center gap-2'>
                  <h3 className=' text-xl font-poppins font-bold max-w-[450px]'>{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              </div>
            ))
          }
          <div className='hidden lg:flex absolute lg:flex-col w-0 left-[calc(50%-45px)] -translate-x-1/2 gap-20 top-0'>
            <div className='border-dashed border-l-2 border-white absolute left-[50px] h-[600px] w-10 z-0'>
            </div>
            <div className=' w-[100px] h-[100px] bg-gradient-to-r from-[#6d7effe8] to-[#00FFB2] flex items-center justify-center rounded-full z-10 relative'>
              <img
                src={value1}
                alt="icon"
                className="w-[70px] h-[70px]"
              />
            </div>
            <div className=' w-[100px] h-[100px] bg-gradient-to-r from-[#6d7effe8] to-[#00FFB2] flex items-center justify-center rounded-full z-10 relative'>
              <img
                src={value2}
                alt="icon"
                className="w-[70px] h-[70px]"
              />
            </div>
            <div className=' w-[100px] h-[100px] bg-gradient-to-r from-[#6d7effe8] to-[#00FFB2] flex items-center justify-center rounded-full z-10 relative'>
              <img
                src={value3}
                alt="icon"
                className="w-[70px] h-[70px]"
              />
            </div>
            <div className=' w-[100px] h-[100px] bg-gradient-to-r from-[#6d7effe8] to-[#00FFB2] flex items-center justify-center rounded-full z-10 relative'>
              <img
                src={value4}
                alt="icon"
                className="w-[70px] h-[70px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyValues
