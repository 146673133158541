import React from "react";
import Header from "../component/Header";
import Footer from "../component/footer/Footer";
import Bg from "../component/BG/Bg";
import ContactForm from "../component/contact form/ContactForm";

const Contact = () => {
    return (
        <div>
            <Bg />
            <Header />

            <div>
                <ContactForm />
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
