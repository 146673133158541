import React from "react";
import "./portfolio_style.css";
import Bg from "../component/BG/Bg";
import Header from "../component/Header";
import Footer from "../component/footer/Footer";
import PortfolioCards from "../component/portfolio_cards/PortfolioCards";

const Portfoilio = () => {
    return (
        <div className="w-full min-h-full">
            <Bg />

            <Header />

            <p className="text-center text-white mt-24 md:mt-24 text-[20px] md:text-[60px]">
                Portfolio <span className="comp_name">ChaitanyaAi</span>
            </p>

            <PortfolioCards />

            <Footer />
        </div>
    );
};

export default Portfoilio;
