import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import emailjs from "@emailjs/browser";
import dots from '../../assets/Case studies/Dots.png';

const Newsletter = () => {

  const [formData, setFormData] = useState({
    email: '',
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs .sendForm('service_irekndn', 
      'template_pg4smwc', 
      form.current, { publicKey: 'SZnjQEdlIN3c4GNEB', })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


  return (
    <>

      <section class="relative max-w-7xl mx-auto p-8">

        <div class="absolute left-0 bottom-0 bg-[#35B5D4] h-32 w-32 
          rounded-bl-[3rem] transform"></div>
        <img src={dots} class="absolute right-0 top-0 transform" />

        <div class="relative bg-[#f4f9ff] rounded-[3rem] shadow-lg py-16 px-8 
          flex flex-col lg:flex-row items-center justify-between">
          <div class="mb-6 lg:mb-0">
            <h2 class="text-4xl font-bold mb-2">Subscribe Newsletter</h2>
            <p class="text-gray-600 text-sm">
              I will update good news and promotion service not spam</p>
          </div>

          <div class="flex w-full lg:w-auto">

            <div class="absolute lg:block hidden right-0 top-0 bottom-0 w-64 
              bg-[#00FFB2] rounded-r-[3rem]"></div>
            <div class="absolute lg:block hidden right-12 top-0 bottom-0 
              w-72 bg-[#00Ffb2] rounded-full"></div>


            <form 
              ref={form} 
              onSubmit={sendEmail}
              className='bg-white z-20 rounded-r-full rounded-l-full lg:p-2 lg:mr-16 
              flex lg:flex-row md:flex-row md:mx-auto flex-col shadow-gray-300 
              shadow-2xl lg:mx-auto'>

              <input type="email" placeholder="Enter your email address..." 
                class="flex-grow px-4 py-2 z-20 rounded-l-full shadow-black 
                focus:outline-none focus:border-cyan-500 w-full lg:w-72"
                id="Email"
                name="Email"
                autoComplete="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required />

              <button class="bg-[#35B5D4] z-20 text-white px-6 py-2 font-bold
                rounded-l-full rounded-r-full hover:bg-cyan-500 transition 
                duration-300 hover:opacity-60 focus:outline-none focus:ring-2 
                focus:ring-blue-500 w-full active:bg-white focus:ring-opacity-50"
                type="submit" 
                value="Send"> 

                Contact Now
              </button>

            </form>

          </div>
        </div>
      </section>

      {/* <section className="border-blue-800 p-8 border lg:p-8 lg:m-20 lg:mx-60 */} 
      {/*   rounded-3xl"> */}
      {/*   <form */} 
      {/*     className="bg-white lg:gap-8 rounded-[50px] lg:grid lg:p-8 */} 
      {/*     lg:grid-cols-12 p-6" */}
      {/*     ref={form} */} 
      {/*     onSubmit={sendEmail}> */}

      {/*     <div className="absolute right-52 bg-green-400 h-[200px] w-[400px] */} 
      {/*       rounded-l-full"> */}
      {/*     </div> */}

      {/*     <div className="absolute left-52 top-14 bg-blue-400 h-[200px] */} 
      {/*       w-[200px] rounded-bl-[100px] z-10"> */}
      {/*     </div> */}

      {/*     <p className="text-[#00FFB2] text-2xl lg:text-4xl font-bold */} 
      {/*       lg:col-span-1"> */}

      {/*       Signup to our Newsletter! */}
      {/*     </p> */}

      {/*     <div className="lg:col-span-3"> </div> */}

      {/*     <div className="col-span-4 flex my-auto"> */}
      {/*       <input */}
      {/*         className="w-full px-3 py-2 bg-transparent border-b my-4 */}
      {/*         border-white focus:outline-none focus:border-blue-500 */} 
      {/*         focus:bg-white focus:text-black text-white placeholder-gray-300" */}
      {/*         id="Email" */}
      {/*         name="Email" */}
      {/*         type="email" */}
      {/*         autoComplete="email" */}
      {/*         placeholder="your@email.com" */}
      {/*         onChange={(e) => setFormData({ ...formData, email: e.target.value })} */}
      {/*         required /> */}
      {/*     </div> */}

      {/*     <button */}
      {/*       type="submit" */} 
      {/*       value="Send" */} 
      {/*       className="col-span-4 h-12 text-center flex z-10 */} 
      {/*       my-auto w-full bg-gradient-to-r from-[#6D7DFF80] */} 
      {/*       to-[#00FFB2] text-white font-bold py-2 px-4 hover:opacity-60 */}
      {/*       focus:outline-none focus:ring-2 focus:ring-blue-500 active:bg-white */}
      {/*       focus:ring-opacity-50 rounded-xl transition-colors duration-200" > */}
      {/*       <p className="flex my-auto"> */}
      {/*         Submit */}
      {/*       </p> */}
      {/*     </button> */}

      {/*   </form> */}
      {/* </section> */}
    </>
  )
}

export default Newsletter
