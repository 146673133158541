import React from "react";
import Header from "../component/Header";
import './style.css'
import Bg from "../component/BG/Bg";
import Footer from "../component/footer/Footer";
import Dropdown from "../component/Dropdown/Dropdown";

// import whyNeedChaitanya from '../assets/company/why-need-chaitanyaAI.svg'
// import JoinCard from "../component/company/JoinCard";
// import OurTeam from "../component/company/OurTeam";
// import CompanyValues from "../component/company/CompanyValues";




const data = [
  {
    title: "Personalized product recommendations",
    content: "AI is helping improve customer satisfaction by offering a way to personalize product recommendations. Through data analysis, AI applications look at the company's customer data—such as their purchase history and current store activity—and analyze their preferences. With that data, stores can offer personalized recommendations through email, product pages, and add-on recommendations. For Example: UrbanLyfe, an e-commerce platform utilizes user data like purchase history and browsing behavior to suggest tailored products, enhancing the shopping experience. Through machine learning, it offers personalized recommendations, improving engagement and satisfaction for users seeking urban lifestyle products. "
  },
  {
    title: "Chatbots for customer service",
    content: " AI chatbots are virtual assistants capable of handling customer service using conversational AI. When a customer opens the chatbot on a webpage to start speaking with an agent, AI bots can use generative text to communicate in natural language to help customers with problems. "
  },
  {
    title: "Demand forecasting and inventory optimization",
    content:" Managing inventory has always been challenging for businesses. They need to minimize the cash they spend on inventory while ensuring they have enough stock to meet customer demand. "
  },
  {
    title: " The data analysis abilities of AI help this problem",
    content:" AI can analyze historical data and make predictions about future sales while also tracking vendor delivery performance These predictive models help online retailers determine how much inventory to order in the future and when to place the orders. "
  },
  {
    title: " Dynamic pricing and revenue optimization",
    content:" Dynamic pricing allows online businesses to adjust pricing based on current conditions automatically. This means looking at the current market conditions, inventory, competitor pricing, and other factors to calculate the price that will give the best chance of making sales and improving profit. "
  },
  {
    title: " Voice-enabled shopping",
    content:" Voice assistants provide shoppers with a more personalized and efficient shopping experience. Instead of opening a smartphone or logging onto a computer, shoppers can talk to their smartphone, tell it the product they want, and let an AI find relevant products for them. "
  },
  {
    title: " Customer sentiment analysis",
    content:" Sentiment analysis helps brands understand how customers react to their products and brand. It looks at information posted online on social media and other websites and tags messages into positive, negative, and neutral categories. Going through this process manually is a lot of work—which is where AI helps.AI can use natural language processing (NLP) to look at the meaning of text. It will look at each message posted about a product or brand and classify it. "
  },
  {
    title: " Automating repetitive tasks",
    content:" One of the most beneficial aspects of AI is its ability to help automate tasks. If you have repetitive work you can’t easily eliminate, you can use AI to automate those tasks and remove some of your workload. AI can help handle many tasks—such as offering loyalty discounts to customers, providing customer support to people who need help, managing a sales pipeline, handling data entry,  optimizing campaigns with AI marketing, and other tasks. "
  },
]


const AICommerce = () => {
  return (
    <div className="w-full min-h-full">

      <Bg />

      <Header />

      <div className="lg:mx-[150px]">

        <p className="text-center text-white mt-24 md:mt-24 text-2xl lg:text-[60px] md:text-[60px]">
          <span className="comp_name">AI in E-Commerce: </span>
          How It’s Used, With Examples
        </p>
        <p className="text-justify text-white font-[500] text-base font-poppins mt-16 leading-6 px-10 lg:px-0">
          AI is playing an increasingly important role in the e-commerce industry, giving companies greater abilities to analyze data and offer a better customer experience. Here are some ways AI is making an impact today.
        </p>

        {/* why chaitanya AI */}
        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full lg:w-7/12 text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">Examples:</p>
            <ul className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                Greater personalization
              </li>
              <li>
                Enhanced inventory management
              </li>
              <li>
                Better inventory management
              </li>
              <li>
                Improved fraud detection.
              </li>
              <li>
                Improved fraud detection.
              </li>
            </ul>
          </div>
        </section>

        <section className="w-full flex flex-col justify-between mt-16 text-white gap-8 px-10 lg:px-0">
          <p className="font-bold text-[26px] leading-8">
            What is AI in e-Commerce?
          </p>
          <p className=" leading-6">
            AI and machine learning in eCommerce involves using artificial intelligence technology in operating an online retail business.

            <br/>
            <br/>
            It means applying advanced algorithms to enhance various aspects of the eCommerce experience, such as product recommendations and customized marketing campaigns.

            <br/>
            <br/>
            In eCommerce, AI helps business owners streamline their operations, create profiles of their customers based on shopping patterns, and enhance their digital assets to improve buyer experience.

            <br/>
            <br/>
            By applying the technology of AI and machine learning in eCommerce, online sellers can personalize product offers, automate basic customer services, and even prevent fraud.

            <br/>
            <br/>

            <spam className='font-bold'>
              Successful eCommerce companies like Amazon and Shopify, for instance, and startups like Urbanlyfe have implemented and developed artificial intelligence in many areas. AI software like Amazon Lex, AWS, and Shopify Magic improve customer experience, online user targeting, and product selection.

            </spam>
          </p>
        </section>


        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">
              How is AI being used in e-commerce?
            </p>
            As you can see, AI has many benefits when used in the right application. So, how are e-commerce retailers using AI in practice? Here are the most common use cases of AI in stores today.
            <div className='w-full'>
              {data && data.map(item => (
                <Dropdown title={item.title} content={item.content} />
              ))}
            </div>
          </div>
        </section>




        <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-52'>
          <div className='flex flex-col lg:flex-row bg-black bg-opacity-25 text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-4xl px-10 lg:px-0'>
              Examples of AI in e-commerce
            </div>
            <div className='w-full lg:w-3/4 px-10 lg:px-0'>
              AI is currently used in organizations of all sizes—from small businesses to large enterprises. Here are a few examples of companies using AI today to enhance the online shopping experience.
            </div>
          </div>
        </section>




        <section className="w-full flex justify-between mt-16 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full text-white flex flex-col gap-8">
            <ol className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>
                <span className="font-bold">
                  Amazon:</span> Amazon uses AI in many areas of its operations. It relies on AI programs to analyze customer data to recommend relevant products, offers chatbots for customer interactions, offers voice-enabled shopping using Amazon Alexa, and suggests more relevant search terms in the search bar.
              </li>


              <li>
                <span className="font-bold">
                  Akibaba:</span> Alibaba created an AI system called the Recommendation System Framework (RSF) to optimize traffic flow and recommend relevant products to shoppers. The system analyzes behavior in real time and offers recommendations within seconds—offering shoppers the best products at the moment of the interaction.
              </li>


              <li>
                <span className="font-bold">
                  UrbanLyfe:</span> The UrbanLyfe e-commerce platform uses AI to empower its users to offer better store experiences and get more done on the back end. It offers a generative AI tool to help create product descriptions and several AI plug-ins that suggest personalized shopping recommendations to customers.
              </li>

            </ol>
          </div>

        </section>


        <section className='w-full bg-gradient-to-r from-[#00FFB2] to-[#6D7DFF] my-44 p-[2px] rounded-xl h-full lg:h-52'>
          <div className='flex flex-col lg:flex-row bg-black text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-4xl px-10 lg:px-0'>
              What Are the Impacts of AI on e-Commerce?

            </div>
            <div className='w-full lg:w-3/4 px-10 lg:px-0'>
              AI has had profound effects, both good and not-so-good, on the world of eCommerce.
            </div>
          </div>
        </section>







        <section className='w-full text-white my-44 p-[2px] rounded-xl h-fit'>
          <div className="flex flex-col">
            <h1 className="text-5xl text-center my-7">
              Positive Impacts
            </h1>
            <p className="text-center text-3xl my-2">
              The benefits of AI can be felt by both parties in an online transaction.
            </p>
          </div>

          <div className="flex flex-row my-5">
            <div className="w-full">
              <h1 className="text-3xl text-center text-white my-5 bg-gradient-to-r from-[#00FFB250] to-[#6D7DFF88] rounded-l-lg">
                For online shoppers:
              </h1>
              <ul className="text-center text-2xl gap-2 flex flex-col">
                <li>
                  A more enjoyable shopping experience,
                </li>
                <li>
                  Better choices in products and prices,
                </li>
                <li>
                  Faster resolution of issues,
                </li>
                <li>
                  Less returns on purchases.
                </li>
                <li>
                  MORE: Retailer returns report
                </li>
              </ul>
            </div>
            <div className="w-full"> 
              <h1 className="text-3xl text-center text-white my-5 bg-gradient-to-r from-[#6D7DFF88] to-[#00FFB250] rounded-r-lg">
                For sellers:
              </h1>
              <ul className="text-center text-2xl gap-2 flex flex-col">
                <li>
                  More accurate and relevant customer information,
                </li>
                <li>
                  Higher numbers of satisfied and loyal customers,
                </li>
                <li>
                  Savings of time and money,
                </li>
                <li>
                  Diminished inventory wastage,
                </li>
                <li>
                  Greater workforce productivity.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <h1 className="text-3xl text-center my-5">
              Negative Impacts
            </h1>
            <ul className="text-center text-2xl gap-2 flex flex-col">
              <li>
                Job Displacement
              </li>
              <li>
                Potential Breaches in Privacy and Data
              </li>
              <li>
                Bias and Discrimination
              </li>
            </ul>
          </div>
        </section>

        <section className='w-full mt-10 p-[2px] rounded-xl h-full lg:h-52'>
          <div className='flex flex-col lg:flex-row bg-black bg-opacity-35 border-red-900 border text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-center text-3xl px-10 lg:px-0'>
              <h1 className="text-red-50 font-bold mb-5"> For example </h1>
              A Statista survey found that more than 
              <span className="text-red-800 font-bold"> 40% </span>
              of online shoppers of the Baby Boomers generation are not comfortable with using AI for personalization.
            </div>
          </div>
        </section>

        <section className='w-full my-40 p-[2px] rounded-xl h-full lg:h-52'>
          <div className='flex flex-col border-green-700 border lg:flex-row bg-black bg-opacity-25 text-white rounded-xl gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-center text-3xl px-10 lg:px-0'>
              <h1 className="text-green-500 font-bold mb-5"> Tip </h1>
              Businesses need to be aware of these potential negative impacts and take steps to avoid such risks in their AI applications.
            </div>
          </div>
        </section>


        <p className="text-center text-white mt-24 md:mt-24 text-2xl lg:text-[60px] md:text-[60px]">
          The Future of AI in
          <span className="comp_name"> E-Commerce </span>
        </p>
        <p className="text-justify text-white font-[500] text-base font-poppins mt-16 leading-6 px-10 lg:px-0">
          As we look ahead, the possibilities of AI and machine learning in eCommerce seem endless. According to research, AI in eCommerce will be valued at over $36 million by 2025 as more retailers continue to utilize it in harvesting customer data to provide increasingly personalized offers.
          <br/>
          Also, those who haven’t yet will apply AI to inventory management and demand forecasting to spend less and earn more. This will bring about $20 billion in AI spending by 2026.
          <br/>
          And compared to the 85 million job losses due to AI by 2025, AI-related roles will employ 97 million individuals across all industries.
          <br/>
        </p>


        {/* <h1 className="text-3xl text-center text-white my-5 bg-gradient-to-r from-[#6D7DFF88] to-[#00FFB250] rounded-r-lg"> */}
        <section className='w-full bg-gradient-to-r from-[#00FFB2] to-[#6D7DFF] my-40 p-[2px] rounded-xl h-full'>
          <div className='flex flex-col lg:flex-row bg-black text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
            <div className='font-bold text-center px-10 lg:px-0'>
              <div className="comp_name my-5 text-4xl"> Takeaway Points </div>
              <p>
        The benefits of AI in eCommerce are undeniable.From chatbots providing instant support to automated checkouts that slash shopping time, customers now enjoy a more delightful online shopping experience.
                <br/>
                <br/>

        For online sellers, creating a personalized transaction for each buyer encourages purchase, while controlled supply chains reduce costs.
                <br/>
                <br/>

        Overall, AI has transformed eCommerce by enhancing efficiency, personalization, and customer engagement, resulting in outstanding business outcomes.And this is not limited to retail giants like Amazon. With the accessibility of AI technology, even small business owners like you can dream big.
                <br/>
                <br/>
              </p>

            </div>
          </div>
        </section>










      </div>

      <Footer />

    </div>
  );
};

export default AICommerce;
