import React from 'react'
import './style.css'
const Bg = () => {
  return (
    <div className='cont_bg'>
        <div className='bubble1'></div>
        <div className='bubble2'></div>
    </div>
  )
}

export default Bg
