import React from "react";
import Header from "../component/Header";
import './style.css'
import GroupDropdowns from "../component/Dropdown/GroupDropdowns";
import Bg from "../component/BG/Bg";
import Platforms from "../component/platforms/Platforms";
import Experties from "../component/experties/Experties";
import Partners from "../component/partner/Partners";
import GetInTouch from "../component/get_in_touch/GetInTouch";
import Testimonial from "../component/testimonil/Testimonial";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom";
import Certifications from "../component/Certifications/certifications";
// import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'



const Home = () => {
  return (
    <div className="w-full min-h-full">

      <Bg />

      <Header />

      <p className="text-center text-white mt-24 md:mt-48 text-[20px] md:text-[60px]">
        Welcome to <span className="comp_name">ChaitanyaAi</span><br />
        Your Strategic AI Partner
      </p>
      <p className="text-center m-auto relative mt-20 text-white w-[70%]">
        ChaitanyaAi transcends traditional AI service providers, emerging as your trusted partner,<br /> We empower data-driven decisions through scalable Data Engineering, operationalized Machine Learning, and actionable Analytics.
      </p>

      <div className="w-full flex mt-20">
        <button className="mx-auto rounded-full w-[20rem] h-14 text-center font-bold text-white 
          bg-gradient-to-l from-[#00FFB2] to-[#6D7DFF]">

          <a href="https://calendly.com/chaitanyaaillp/introduction-call" target='_blank' rel="noopener noreferrer">

            Schedule a Discovery Meeting
          </a>
        </button>

      </div>
      <div className="w-[70%] mx-auto">
        <GroupDropdowns />
      </div>

      <Platforms />

      <Experties />

      <Partners />

      <GetInTouch />

      <Testimonial />

      <Certifications />

      <Footer />


    </div>
  );
};

export default Home;
