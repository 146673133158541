import React from 'react'

const JoinCard = () => {
  return (
    <section className='w-full bg-gradient-to-r from-[#00FFB2] to-[#6D7DFF] mt-10 p-[2px] rounded-xl h-full lg:h-52'>
      <div className='flex flex-col lg:flex-row bg-black text-white rounded-xl py-4 gap-8 lg:px-14 lg:gap-16 h-full items-center font-inter'>
        <div className='font-bold text-4xl px-10 lg:px-0'>
          Join Us in Shaping
          the
          Future of AI
        </div>
        <div className='w-full lg:w-3/4 px-10 lg:px-0'>
          At ChaitanyaAi, we believe in embracing growth and continuous learning, creating a collaborative environment that stands out with our unique problem-solving approaches. Join us in pioneering AI with passion - where every line of code is crafted with love.
        </div>
      </div>
    </section>
  )
}

export default JoinCard
