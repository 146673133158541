import React from "react";
import Header from "../component/Header";
import './style.css'
import Bg from "../component/BG/Bg";
import Footer from "../component/footer/Footer";
import whyNeedChaitanya from '../assets/company/why-need-chaitanyaAI.svg'
import JoinCard from "../component/company/JoinCard";
import OurTeam from "../component/company/OurTeam";
import CompanyValues from "../component/company/CompanyValues";

const Company = () => {
  return (
    <div className="w-full min-h-full">

      <Bg />

      <Header />

      <div className="lg:mx-[150px]">

        <p className="text-center text-white mt-24 md:mt-24 text-2xl lg:text-[60px] md:text-[60px]">
          About <span className="comp_name">ChaitanyaAi</span>
        </p>
        <p className="text-justify text-white font-[500] text-base font-poppins mt-16 leading-6 px-10 lg:px-0">
          Welcome to ChaitanyaAi, where innovation meets dedication. Founded in 2023, we are more than just an AI service provider; we're your trusted partner in unlocking the transformative power of artificial intelligence. Our diverse and talented team collaborates passionately to create a positive impact, working seamlessly on GenerativeAi, AI Consulting, AI Development, Computer Vision, AI Integration, and AI Support.
        </p>

        {/* why chaitanya AI */}
        <section className="w-full flex justify-between mt-24 flex-col-reverse lg:flex-row items-center lg:items-start px-10 lg:px-0 gap-10 lg:gap-0">
          <div className="w-full lg:w-7/12 text-white flex flex-col gap-8">
            <p className="font-bold text-[26px] leading-8">Why ChaitanyaAI?</p>
            <ul className="list-disc list-outside text-justify flex flex-col gap-8 ml-4">
              <li>Trusted Partnership: We bridge the gap between cutting-edge technology and real-world challenges, weaving expertise into bespoke solutions that propel your business forward.</li>
              <li>Democratizing AI: Our mission is to make AI accessible for every business, irrespective of size or industry, ensuring it's understandable and tailored to individual needs.</li>
              <li>
                Empowering Teams: Beyond technology implementation, we empower your team to leverage AI effectively, fostering a culture of data-driven decision-making and continuous innovation.
              </li>
            </ul>
          </div>
          <div className="">
            <img
              src={whyNeedChaitanya}
            />
          </div>
        </section>

        <section className="w-full flex flex-col justify-between mt-16 text-white gap-8 px-10 lg:px-0">
          <p className="font-bold text-[26px] leading-8">Our Unique Approach: Passion and Love in Every Line of Code</p>
          <p className=" leading-6">Innovating software solutions is our forte, fueled by a touch of passion and love. Our work life is a flexible and remote team extension, ensuring seamless collaboration from project launch to maintenance support.
          </p>
        </section>

        <CompanyValues />
        <JoinCard />
        <OurTeam />
      </div>

      <Footer />

    </div>
  );
};

export default Company;
