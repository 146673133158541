import React from 'react'

const Detail = ({data}) => {
  return (
    <div className='p-8 flex flex-col  h-full'>
      <p className='text-white text-[20px]  mb-4'>{data.title}</p>
      <p className='text-white '>{data.desc}</p>
      <img src={data.imgSrc} alt='bot' className='hidden absolute bottom-10 right-10 md:flex w-36 lg:size-44 lg:flex' />
    </div>
  )
}

export default Detail
