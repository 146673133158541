import React from "react";
import Background1 from "../public/Background.png";
import Background2 from "../public/Background.png";
import Background3 from "../public/Background.png";
import Background4 from "../public/Background.png";

const Problemident = () => {
  return (
    <div className="Problem flex flex-col gap-3 items-center mt-28 lg:mt-60
      lg:w-[1200px] m-auto">
      <div>
        <h2 className="text-center font-black text-[#282B2F] text-[46.31px]">
          Problem Identifies
        </h2>
        <div className="text-center px-4 font-medium text-[#000000]/50 text-[16px]">
          <p>We at Chaitanya AI follow a detailed Problem Identification process to
            deliver excellence.</p>
          <p>Let's dive deep into the process.</p>
        </div>
      </div>
      <div className="flex flex-col gap-5 lg:flex-row">
        <div className="card p-5 shadow-lg flex flex-col h-[362px] 
          rounded-[24px] gap-2">
          <img
            className="cover"
            src={Background1}
            width={60}
            height={60}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[218px]">Limited Design Options</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[215.67px]">Users might find the built-in design options limiting, restricting their ability to find the perfect wallpaper for their space</p>
        </div>
        <div className="card p-5 shadow-lg flex flex-col h-[362px] w-[279px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Background2}
            width={60}
            height={60}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[218px]">Difficulty in Visualizing</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[215.67px]">Some users may struggle to visualize how a particular wallpaper design will look in their room, leading to uncertainty and hesitation in making a decision.</p>
        </div>
        <div className="card p-5 shadow-lg flex flex-col h-[362px] w-[279px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Background3}
            width={60}
            height={60}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[218px]">Lack of Customization</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[215.67px]">While users can upload their own designs, they may encounter limitations in terms of customization options, such as adjusting scale or colors to fit their preferences.</p>
        </div>
        <div className="card p-5 shadow-lg flex flex-col h-[362px] w-[279px] rounded-[24px] gap-2">
          <img
            className="cover"
            src={Background4}
            width={60}
            height={60}
            alt=""
          />
          <h3 className=" font-bold text-[19.38px] w-[218px]">User Interface Complexity</h3>
          <p className=" text-[#636B76] font-normal text-[14px] w-[215.67px]">The platform's interface could be complex or unintuitive, making it challenging for users to navigate and utilize all available features effectively.</p>
        </div>
      </div>
    </div>
  );
};

export default Problemident;
